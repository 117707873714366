import React from 'react';
import b1 from "../..//assets/images/blog/blog-01.png"

function Batchcard({ param }) {
  return (
    <>
    
    <div className="edu-blog blog-style-1">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href={`${param.url}`}>
                                        <img src={b1} alt="Blog Images"/>
                                    </a>
                                </div>
                                <div className="content position-top">
                                    <div className="read-more-btn">
                                        <a className="btn-icon-round" href={`${param.url}`}><i className="icon-4"></i></a>
                                    </div>
                                    <div className="category-wrap">
                                        <a href={param.url} className="blog-category text-success custom-badge">{param.category}</a>
                                    </div>
                                    <h5 className="title" style={{color: "#0d6efd"}}><a href={`${param.url}`}>{param.title}</a></h5>
                                    <ul className="blog-meta">
                                        <li><i className="icon-phone"></i>{param.date}</li>
                                        {/* <li><i className="icon-28"></i>Com 09</li> */}
                                    </ul>
                                    {/* <p>{param.sm_desc}</p> */}
                                </div>
                            </div>
                        </div>
    
    
    
    </>
  )
}

export default Batchcard;