import React from 'react';

function CustomSingleNavDropDown() {
  return (
    <>
         <ul className="submenu custom-single-drop">
            <li><a ><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; Magazine</a></li>
            <li><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Current Affairs</a></li>
            <li><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Frequently Asked Questions</a></li>
            <li><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  General Essays</a></li>
            <li><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Current Affairs Content</a></li>
            <li><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Multiple Choice Questions</a></li>
         </ul>
    </>
  )
}

export default CustomSingleNavDropDown;