import React from "react";

const HeroCard = () => {
    // const { mouseReverse } = useMouseMoveUI();

    const cardData = [
        { title: "Courses", href: "/course" },
        { title: "Knowledge Zone", href: "#" },
        { title: "Interview", href: "#" },
        { title: "Test Series", href: "#" },
        { title: "Videos", href: "#" },
        { title: "Daily Quiz", href: "#" },
        { title: "Articles", href: "/articles" },
        { title: "Blog", href: "/blogs" },
    ];

    return (
        <>
        <div className='container-fluid Banner-background p-5'>
            <div className='container-fluid mx-auto p-1 px-5'>
                <div className='row'>
                {cardData.map((card, index) => (
                    <div className='col-lg-3 p-2' key={index}>
                        <a href={card.href} className='card-background p-5'>
                            <div className='bann-card'>
                                <h5 className='banner-title'>
                            {card.title}
                            </h5>
                            </div>
                        </a>
                    </div>
                ))}
                </div>
            </div>
        </div>
        </>
    );
};

export default HeroCard;
