import { createContext, useState, useContext } from 'react';
import { fetchAllArticlesDate, fetchAllBlogsDate } from '../../Config/api'; 

const DateContext = createContext();

export function DateProvider({ children }) {
    const [filterblogs, setFilterblogs] = useState([]);
    const [filterarticles, setFilterarticles] = useState([]);
    const [isfilter, setIsfilter] = useState(false);
    const [startdate, setStartDate] = useState("2024-05-01");
    const [enddate, setEndDate] = useState(new Date().toISOString().split("T")[0]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);


    const handleStartDateChange = (e) => {
      
        setStartDate(e.target.value);
        console.log(e.target.value)
        console.log(startdate)
      };
    
      const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
        console.log(e.target.value)
        console.log(enddate)
      };
    
      const handleSubmitBlog = async (e, s1, e1) => {
        if (e && e.preventDefault) e.preventDefault();
        setLoading(true);
        setIsfilter(true);
        try {
            const data = await fetchAllBlogsDate(s1, e1);
            setFilterblogs(data.data);
            console.log(data);
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
            // setIsfilter(false);
        }
    };
    

      const handleSubmitarticle = async (e, s1, e1) => {
        if (e && e.preventDefault) e.preventDefault();
        setLoading(true);
        setIsfilter(true);
        try {
            const data = await fetchAllArticlesDate(s1, e1);
            setFilterarticles(data.data); // Set branches data from response
            console.log(data);
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
            // setIsfilter(false);
        }
      };
  
    return (
      <DateContext.Provider value={{ startdate, enddate, filterblogs, filterarticles, isfilter, loading, error, handleStartDateChange, handleEndDateChange, handleSubmitBlog, handleSubmitarticle}}>
        {children}
      </DateContext.Provider>
    );
  }
  
  export function useDateContext() {
    return useContext(DateContext);
  }