import React, { useEffect } from "react";
// import "../../fonts.css"
// import "../../App.css";
// import Courses from "../Card/Coursescard";
import Enquery from "../Form/Enquery";
import { useParams } from "react-router-dom";
import { useBranchContext } from "../Context/Branchapi";
import img1 from "../../assets/images/others/shape-81.png";
import img2 from "../../assets/images/others/shape-65.png";
import img3 from "../../assets/images/cta/shape-08.png";
import img4 from "../../assets/images/others/shape-79.png";
import img5 from "../../assets/images/course/course-12.jpg";
import img6 from "../../assets/images/course/course-46.png";
import img7 from "../../assets/images/svg-icons/books.svg";
import img8 from "../../assets/images/blog/blog-25.jpg";
import img9 from "../../assets/images/blog/small-post-01.jpg";
import img10 from "../../assets/images/blog/small-post-02.jpg";
import img11 from "../../assets/images/blog/small-post-03.jpg";
import { useBackgroundContext } from "../Context/BackgroundChange";
import Coursecardlarge from "../Card/Coursecardlarge";
import Branch from "../Courses/Branch";
import CustomEnquery from "../Form/CustomEnquery";
import { useBlogsContext } from "../Context/Blogsapi";
import BlogCard from "../Card/BlogCard/BlogCard";
import PostCard from "../Card/BlogCard/PostCard";
import Keyword from "../../Component/Keywords/keywords";
import Datepicker from "../Calender/Datepicker/Datepicker";
import Calendar from "../Calender/CalendarComponent/CalendarComponent";
import { DateProvider, useDateContext } from "../Context/Dateapi";

// import useEduJs from '../../Hooks/useEduJs1';

function Blogs() {
  // useEduJs();

//   const { branchUrl } = useParams(); // Extracts branchUrl from the URL
//   const backgroundStyle = useBackgroundContext();
//   console.log(branchUrl);
  const { blogs, loading, error, fetchAllBlogsHandler } =
    useBlogsContext();

  const {isfilter, filterblogs} = useDateContext();  

    const {backgroundStyle} = useBackgroundContext();

  useEffect(() => {
    fetchAllBlogsHandler(); // Fetch branches on component mount
  }, []);

  if (loading) return <p>Loading...</p>;

  // Display the branch information if data is available
  // const branch = branches.find(b => b.url === branchUrl);

//   const branchName = branchDe?.branchname || "Branch Name";
//   const addressHTML = branchDetails?.address || "";

let isvalidblogs = 'blogs';

// const keywordsArray =
//     coursesDetails?.keywords.split(",").map((keyword) => keyword.trim()) || [];

  return (
    <>
      <div className="edu-breadcrumb-area breadcrumb-style-8">
        <div className="breadcrumb-right-wrapper" style={backgroundStyle}>
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="page-title">
                <h2 className="title">Blogs</h2>
              </div>
              <ul className="course-meta">
                <li>
                  <i className="icon-58"></i>by SRIRAM's IAS
                </li>
                <li>
                  <i className="icon-59"></i>English
                </li>
                <li className="course-rating">
                  <div className="rating">
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                  </div>
                  <span className="rating-count">(720 Rating)</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-6 scene">
            <img data-depth="2" src={img1} alt="shape" />
          </li>
          <li className="shape-7 scene">
            <img data-depth="-2" src={img2} alt="shape" />
          </li>
          <li className="shape-8">
            <img src={img3} alt="shape" />
          </li>
          <li className="shape-9 scene">
            <img data-depth="-2" src={img4} alt="shape" />
          </li>
        </ul>
      </div>
      <section className="edu-section-gap course-details-area">
        <div className="container">
          <div className="row row--30 pt-0">
            <div className="col-lg-8">
            <div className="course-details-content course-details-7">
                <ul className="nav pt-5" id="myTab" role="tablist">
                  
            <Datepicker isvalidblogs={isvalidblogs}/> 
           
                </ul>
              <BlogCard blogs={!isfilter ? blogs : filterblogs}/>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="course-sidebar-3 sidebar-top-position">
                <div className="edu-course-widget widget-course-summery">
                  <div className="inner">
                    <div className="">
                    <Calendar/>
                      {/* <img src={img6} alt="Courses" /> */}
                      {/* <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" className="play-btn video-popup-activation"><i className="icon-18"></i></a> */}
                    </div>
                    <div className="content">
                      <h4 className="widget-title">Blogs:</h4>
                      <ul className="course-item">
                        {/* <li>
                          <span className="label">
                            <i className="icon-60"></i>Price:
                          </span>
                          <span className="value price">$70.00</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-62"></i>Instrutor:
                          </span>
                          <span className="value">Edward Norton</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-61"></i>Duration:
                          </span>
                          <span className="value">3 weeks</span>
                        </li> */}
                        <li>
                          <span className="label">
                            <img
                              className="svgInject"
                              src={img7}
                              alt="book icon"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Today's Blog:
                          </span>
                          <span className="value">0</span>
                        </li>
                        <li>
                          <span className="label">
                            <img
                              className="svgInject"
                              src={img7}
                              alt="book icon"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;Total Blog:
                          </span>
                          <span className="value">0</span>
                        </li>
                        {/* <li>
                          <span className="label">
                            <i className="icon-63"></i>Enrolled:
                          </span>
                          <span className="value">65 students</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-59"></i>Language:
                          </span>
                          <span className="value">English</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-64"></i>Certificate:
                          </span>
                          <span className="value">Yes</span>
                        </li> */}
                      </ul>
                      {/* <div className="read-more-btn">
                                            <a href="#" className="edu-btn">Start Now <i className="icon-4"></i></a>
                                        </div> */}
                      {/* <div className="share-area">
                                            <h4 className="title">Share On:</h4>
                                            <ul className="social-share">
                                                <li><a href="#"><i className="icon-facebook"></i></a></li>
                                                <li><a href="#"><i className="icon-twitter"></i></a></li>
                                                <li><a href="#"><i className="icon-linkedin2"></i></a></li>
                                                <li><a href="#"><i className="icon-youtube"></i></a></li>
                                            </ul>
                                        </div> */}
                    </div>

                  </div>
                </div>
              </div>
              {/* <Enquery /> */}
              <CustomEnquery/>
  
              <div className="col-lg-12 mt-5 pt-5">
                        <div className="contact-form form-style-2 px-0 py-0">
                             <div className="edu-blog-widget widget-latest-post mt-5">
                                <div className="inner">
                                    <h4 className="widget-title">Latest Post</h4>
                                    <div className="content latest-post-list px-0">
                                      <PostCard blogs={blogs}/>
                                        {/* <div className="latest-post">
                                            <div className="thumbnail">
                                                <a href="blog-details.html">
                                                    <img src={img9} alt="Blog Images" />
                                                </a>
                                            </div>
                                            <div className="post-content">
                                                <h6 className="title"><a href="blog-details.html">Instructional Design &amp; Adult Learners</a></h6>
                                                <ul className="blog-meta">
                                                    <li><i className="icon-27"></i>22 Nov, 2024</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="latest-post">
                                            <div className="thumbnail">
                                                <a href="blog-details.html">
                                                    <img src={img10} alt="Blog Images" />
                                                </a>
                                            </div>
                                            <div className="post-content">
                                                <h6 className="title"><a href="blog-details.html">Believe in Yourself! Have Faith!</a></h6>
                                                <ul className="blog-meta">
                                                    <li><i className="icon-27"></i>22 Nov, 2024</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="latest-post">
                                            <div className="thumbnail">
                                                <a href="blog-details.html">
                                                    <img src={img11} alt="Blog Images" />
                                                </a>
                                            </div>
                                            <div className="post-content">
                                                <h6 className="title"><a href="blog-details.html">Live Online Lessons & eLearning</a></h6>
                                                <ul className="blog-meta">
                                                    <li><i className="icon-27"></i>22 Nov, 2024</li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                                {/* <Keyword/> */}
                            </div>
                        </div>
                        
                    </div>

                    
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blogs;
