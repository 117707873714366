import React from 'react';

function keywords({keyword}) {
  return (
    <>

    <div className="widget-tags w-100 py-5 pt-0">
                                <div className="inner">
                                    <h4 className="widget-title highlight-custom-background text-white py-4 px-5" style={{borderTopRightRadius: "5px", borderTopLeftRadius: "5px"}}><i className='icon-36'></i>&nbsp;Tags</h4>
                                    <div className="content px-5 py-3">
                                        <div className="tag-list">
                                        {keyword.map((word, index)=>(
                                            <a key={index} className='custom-heading1' href="#">{word}</a>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
    
    
    </>
  )
}

export default keywords;