import React, { useEffect, useState } from 'react';
import "./CivilTopSection.css";
// import { IoLogoFacebook } from "react-icons/io5";
// import { FaTelegram } from "react-icons/fa";
import { useButtonContext } from '../Context/Buttonmanagement';



// Reusable component for displaying user information
function UserInfo({ userImage, userName }) {
  

  return (
    <div className='written'>
      <div className='written-Img'>
        <img src={userImage} alt='...' className='userimg' />
      </div>
      <div className='written-heading ms-3 pt-2'>
        <h5 className='fs-6 text-white'>Written By</h5>
        <h3 className='fs-6 fw-bold text-white'>{userName}</h3>
      </div>
    </div>
  );
} 

// Reusable component for the Civil Top section
function CivilTopSection({ academyName, academyName1, userImage, userName, Image, subacademy, componentName, hasUpdated,setHasUpdated }) {
  const { buttonNames,  activeButton ,updateButtonNames, handleButtonClick } = useButtonContext();

  useEffect(() => {
    if(!hasUpdated){
      updateButtonNames(componentName); // Update button names based on componentName
      setHasUpdated(true)
    }
}, [componentName, updateButtonNames]);
  console.log(academyName1)
  const [firstHalf, setFirstHalf] = useState('')
  const [secondHalf, setSecondHalf] = useState('')
  const [thirdHalf, setThirdHalf] = useState('')

  function splitStringIntoThreeParts(inputString) {
    const length = inputString.length;
    const partLength = Math.floor(length / 3); // Approximate length for each part
    const parts = [];

    let startIndex = 0;
    let endIndex = partLength;

    for (let i = 0; i < 2; i++) { // Loop for the first two parts
      // Find the index to split the string without breaking a word
      while (inputString[endIndex] !== ' ' && endIndex > startIndex) {
        endIndex--;
      }
      parts.push(inputString.substring(startIndex, endIndex).trim());
      startIndex = endIndex + 1;
      endIndex = startIndex + partLength;
    }

    // Last part includes the remaining string
    parts.push(inputString.substring(startIndex).trim());

    return parts;
  }

  useEffect(() => {
    if (academyName1 && !academyName) {
      const parts = splitStringIntoThreeParts(academyName1);
      setFirstHalf(parts[0]);
      setSecondHalf(parts[1]);
      setThirdHalf(parts[2]);
    }
  }, [academyName1, academyName])

    // Determine the column class based on buttonNames length
    const columnClass = buttonNames.length <= 4 ? 'col-lg-4 py-2' : 'col-lg-3 py-2';


  return (
    <div className='civilTop-container'>
      <div className='CivilTop-left d-flex flex-column justify-content-around align-items-center' style={{width: "20%"}}>
        {academyName && <h1 className='wow animate__animated animate__backInDown py-0 pt-0 text-white title' style={{fontSize: "1vmax"}}>{subacademy}</h1>}
        {academyName && <h2 className='wow animate__animated animate__backInDown py-0 pt-0 text-white title' style={{fontSize: "1vmax"}}>{academyName}</h2>}
      </div>
      <div className='CivilTop-right d-flex justify-content-center' style={{width: "80%"}}>
      <div className='container'>
          <div className='row'>
          {buttonNames.map((name, index) => (
        <div className={columnClass}  key={index}>
          <button className={`${activeButton === name.toLowerCase().replace(/\s+/g, '') ? 'edu-btn2' : "edu-btn edu-btn1"}`} onClick={()=>{handleButtonClick(name)}}>{name}</button>
        </div>
        ))}
        </div>
        </div>
      </div>
    </div>
  );
}

export default CivilTopSection;
