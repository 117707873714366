import React, { useState } from 'react';
import { ButtonProvider } from '../Context/Buttonmanagement';
import CivilTopSection from '../CivilTopSection/CivilTopSection';

function AllSriCourse() {
    const [hasUpdated, setHasUpdated] = useState(false);

      // Banner Content For CivilAbout Page
  const data = {
    subacademy: 'OUR',
    academyName: `Branches »»`,
    // userImage: userImage,
    userName: 'Priya Rathore',
    // Image: aboutImage
  };

  const componentName = 'sriCourse';
  return (
    <>
            {/* <div className="edu-breadcrumb-area"> */}
            {/* <div className="container"> */}
                {/* <div className="breadcrumb-inner">
                    <div className="page-title">
                        <h1 className="title">Course Style 1</h1>
                    </div>
                    <ul className="edu-breadcrumb">
                        <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        <li className="breadcrumb-item"><a href="#">Courses</a></li>
                        <li className="separator"><i className="icon-angle-right"></i></li>
                        <li className="breadcrumb-item active" aria-current="page">Course Style 1</li>
                    </ul>
                </div> */}
            {/* </div> */}
            <section className='civilabout'>
            <ButtonProvider>
        <CivilTopSection
          hasUpdated={hasUpdated}
          setHasUpdated={setHasUpdated}
          componentName={componentName}
          subacademy={data.subacademy}
          academyName={data.academyName}
          // userImage={data.userImage}
          userName={data.userName}
          // Image={data.Image}
        />
        </ButtonProvider>
        </section>

            {/* <ul className="shape-group">
                <li className="shape-1">
                    <span></span>
                </li>
                <li className="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape"/></li>
                <li className="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape"/></li>
                <li className="shape-4">
                    <span></span>
                </li>
                <li className="shape-5 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="shape"/></li>
            </ul>
        </div> */}
    
    </>
  )
}

export default AllSriCourse;