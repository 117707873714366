import React from 'react';
import About from '../About/About';
import Batch from '../Batch/Batch';
import Categories from '../Categories/Categories';
import Testimonial from '../Testimonial/Testimonial';
import HeroCard from '../CustomBanner/hero-card';
import CTA from '../CTA/CTA';
import Highlights from '../Highlights/Highlights';
import Instructors from '../Instructors/Instructors';
import Offers from '../Offers/Offers';
import Partners from '../Partners/Partners';

function Home() {
  return (
    <>
    <HeroCard/>
<Highlights/>
<Batch/>
<About/> 
<Categories/>
<Testimonial/>
<CTA/>
<Instructors/>
    </>
  )
}

export default Home;