import React from 'react';

function SingleNabar1() {
  return (
    <>
     <ul className="submenu custom-single-drop"> 
     <li className=''><a href="/course-details/general-studies-module-course"><span className='course-level bg-primary text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; General Studies Module Course</a></li>
      <li className=''><a href="/course-details/csat-only-at-sriramsias"><span className='course-level bg-primary text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; CSAT</a></li>
      <li className=''><a href="/course-details/mains-mastery-course-2025"><span className='course-level bg-primary text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; Mains Mastery Course 2025</a></li>
         <li className=''><a href="/course-details/optional-subject-course"><span className='course-level bg-primary text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; Optional Subject Course</a></li>
      <li className=''><a href="/course-details/general-studies-comprehensive-course"><span className='course-level bg-primary text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; 1 Year General Studies Comprehensive Course</a></li>
      <li className=''><a href="/course-deatils/2-years-general-studies-foundation-course"><span className='course-level bg-primary text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; 2 Years General Studies Classroom Foundation Course</a></li>
                                            </ul>
    
    
    
    
    
    
    
    </>
  )
}

export default SingleNabar1;