import React from 'react'
import  instructors_data  from "../../Data/instructors";
import Team from '../Team/Team';

function Instructors() {
  return (
    <>
    <div className="edu-team-area team-area-1 gap-tb-text">
            <div className="container">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <span className="pre-title">Faculty</span>
                    <h2 className="title">Our Faculty Member</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <div className="row g-5">
                    {/* <!-- Start Instructor Grid  --> */}
                    {instructors_data.slice(0,4).map((instructor) => (
                        <div key={instructor.id} className="col-lg-3 col-sm-6 col-12" data-sal-delay={instructor.delay} data-sal="slide-up" data-sal-duration="800">
                            <Team instructor={instructor}/>
                        </div>
                    ))}
                    {/* <!-- End Instructor Grid  --> */}
                </div>
            </div>
        </div>
    </>
  )
}

export default Instructors;