import React, { useEffect } from "react";
import useEduJs from '../../Hooks/useEduJs1';
import SingleNabar from '../NavbarDropdown/SingleNabar/SingleNabar';
import MainMenu from '../NavbarDropdown/MainMenu/MainMenu';
import logoimg from "../../Logo-1.png";
import { BranchProvider } from '../Context/Branchapi';
import { useWebdetailsContext } from '../Context/WebDetails';
import { useNewsheadlineContext } from "../Context/NewsHeadline";

function Header() {
    const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();
    const { newsheadline, fetchNewsheadlineByUrlHandler } =
    useNewsheadlineContext();

//   const {isfilter, filterblogs} = useDateContext();  

    // const {backgroundStyle} = useBackgroundContext();

  useEffect(() => {
    fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
    fetchNewsheadlineByUrlHandler();
  }, []);

  const Mailid = Webdetails?.adminemail || "Email Id";
  const phone = Webdetails?.callnumber || "";
  const flink = Webdetails?.facebook || "";
  const ilink = Webdetails?.instagram || "";
  const tlink = Webdetails?.twitter || "";
  const ylink = Webdetails?.youtube || "";
//   const course = Webdetails?.totalcourse || "";
//   const batch = Webdetails?.totalbatch || "";

    const { stickyPlaceholderRef, menuRef } = useEduJs();
  return (
    <>
<header className="edu-header header-style-1 header-fullwidth">
<div className="header-top-bar">
                        <div className="container-fluid">
                            <div className="header-top">
                                <div className="text-center">
                                <div className="header-notify">
                                <div className="marqueeContainer">
      <div className="marqueeContent">
        {newsheadline.map((line, index)=>(
        <a href={line.linkurl} key={index} className="marqueeText text-white"><i className="icon-81"></i>{line.headline}</a>
        ))}
                <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> UPSC Prelims 2024 Questions Reflections from Vajiram Test Series</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> UPSC Prelims Result 2024 Out: Check Live</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> GS Live-Online August Batch - Direct Admission</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> StepUp Mentorship Counselling Form</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> Optional Subject Programme Counselling Form</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> New WhatsApp Channel Join Now!</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> UPSC Prelims 2024 Questions Reflections from Vajiram Test Series</span>
        <span className="marqueeText" style={{opacity: "0"}}><i className="icon-81"></i> UPSC Prelims Result 2024 Out: Check Live</span>
        {/* Add more items as needed */}
      </div>
    </div>          
        </div>
                                </div>
                            </div>
                        </div>
                    </div>
            <div className="header-top-bar">
                <div className="container-fluid">
                    <div className="header-top">
                        <div className="header-top-left">
                            <div className="header-notify">
                            Beware of fake institutes with similar names.<a href="#">SRIRAM's IAS</a>
                            </div>
                        </div>
                        <div className="header-top-right">
                            <ul className="header-info">
                                <li><a href="https://student.sriramsias.com/">Login</a></li>
                                <li><a href="registration">Register</a></li>
                                <li><a href="tel:+919811489560"><i className="icon-phone"></i>Call: {phone}</a></li>
                                <li><a href="mailto:sriramsias@gmail.com" target="_blank"><i className="icon-envelope"></i>{Mailid}</a></li>
                                <li className="social-icon">
                                    <a href={`${flink}`}><i className="icon-facebook"></i></a>
                                    <a href={`${ilink}`}><i className="icon-instagram"></i></a>
                                    <a href={`${tlink}`}><i className="icon-twitter"></i></a>
                                    <a href={`${ylink}`}><i className="icon-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="edu-sticky-placeholder" ref={stickyPlaceholderRef}></div>
            <div className="header-mainmenu" ref={menuRef}>
                <div className="container-fluid">
                    <div className="header-navbar">
                        <div className="header-brand">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-light" src={logoimg} alt="Corporate Logo"/>
                                    <img className="logo-dark" src={logoimg} alt="Corporate Logo"/>
                                </a>
                            </div>
                            <div className="header-category">
                                <nav className="mainmenu-nav">
                                    <ul className="mainmenu">
                                        <li className="has-droupdown">
                                            <a href="#"><i className="icon-1"></i>BRANCHES</a>
                                            <BranchProvider>
                                           <SingleNabar/> 
                                           </BranchProvider>
                                        </li>
                                    </ul>
                                </nav>
                            </div> 
                        </div>
                        <div className="header-mainnav">
                            <nav className="mainmenu-nav">
                                <MainMenu/>
                            </nav>
                        </div>
                        <div className="header-right">
                            <ul className="header-action">
                                
                                {/* <li className="search-bar">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search"/>
                                        <button className="search-btn" type="button"><i className="icon-2"></i></button>
                                    </div>
                                </li>
                                <li className="icon search-icon">
                                    <a href="javascript:void(0)" className="search-trigger">
                                        <i className="icon-2"></i>
                                    </a>
                                </li>
                                <li className="icon cart-icon">
                                    <a href="cart.html" className="cart-icon">
                                        <i className="icon-3"></i>
                                        <span className="count">0</span>
                                    </a>
                                </li>
                                <li className="header-btn">
                                    <a href="contact-us.html" className="edu-btn btn-medium">Try for free <i className="icon-4"></i></a>
                                </li> */}
                                <li className="mobile-menu-bar d-block d-xl-none">
                                    <button className="hamberger-button">
                                        <i className="icon-54"></i>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="popup-mobile-menu">
                <div className="inner">
                    <div className="header-top">
                        <div className="logo">
                            <a href="index.html">
                                <img className="logo-light" src="assets/images/logo/logo-dark.png" alt="Corporate Logo"/>
                                <img className="logo-dark" src="assets/images/logo/logo-white.png" alt="Corporate Logo"/>
                            </a>
                        </div>
                        <div className="close-menu">
                            <button className="close-button">
                                <i className="icon-73"></i>
                            </button>
                        </div>
                    </div>
                    <ul className="mainmenu">
                        <li className="has-droupdown"><a href="#">Home</a>
                            <ul className="mega-menu mega-menu-one">
                                <li>
                                    <ul className="submenu mega-sub-menu mega-sub-menu-01">
                                        <li><a href="index.html">EduBlink Education <span className="badge-1">hot</span></a></li>
                                        <li><a href="index-distant-learning.html">Distant Learning</a></li>
                                        <li><a href="index-university.html">University</a></li>
                                        <li><a href="index-online-academy.html">Online Academy <span className="badge-1">hot</span></a></li>
                                        <li><a href="index-modern-schooling.html">Modern Schooling</a></li>
                                        <li><a href="index-kitchen.html">Kitchen Coach</a></li>
                                        <li><a href="index-yoga.html">Yoga Instructor</a></li>
                                        <li><a href="index-kindergarten.html">Kindergarten</a></li>
                                        <li><a href="index-health-coach.html">Health Coch</a></li>
                                        <li><a href="index-language-academy.html">Language Academy</a></li>
                                        <li><a href="index-remote-training.html">Remote Training</a></li>
                                        <li><a href="index-photography.html">Photography</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul className="submenu mega-sub-menu mega-sub-menu-01">
                                        <li><a href="index-business-coach.html">Business Coach <span className="badge">new</span></a></li>
                                        <li><a href="index-motivation.html">Motivation <span className="badge">new</span></a></li>
                                        <li><a href="index-programming.html">Programming <span className="badge">new</span></a></li>
                                        <li><a href="index-online-art.html">Online Art <span className="badge">new</span></a></li>
                                        <li><a href="index-digital-marketing.html">Digital Marketing <span className="badge">new</span></a></li>
                                        <li><a href="index-sales-coach.html">Sales Coach <span className="badge">new</span></a></li>
                                        <li><a href="index-quran-learning.html">Quran Learning <span className="badge">new</span></a></li>
                                        <li><a href="index-gym-tranning.html">Gym Tranning <span className="badge">new</span></a></li>
                                        <li><a href="https://www.edublink.html.dark.devsblink.com/index.html">Dark Version</a></li>
                                        <li><a href="https://edublink.html.rtl.devsblink.com">Rtl Version</a></li>
                                        <li><a href="index-landing.html">Landing Demo</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <ul className="submenu mega-sub-menu-01">
                                        <li>
                                            <a href="https://1.envato.market/5bQ022">
                                                <img src="assets/images/others/mega-menu-image.webp" alt="advertising Image"/>
                                            </a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="has-droupdown"><a href="#">Pages</a>
                            <ul className="mega-menu">
                                <li>
                                    <h6 className="menu-title">Inner Pages</h6>
                                    <ul className="submenu mega-sub-menu-01">
                                        <li><a href="about-one.html">About Us 1</a></li>
                                        <li><a href="about-two.html">About Us 2</a></li>
                                        <li><a href="about-three.html">About Us 3</a></li>
                                        <li><a href="team-one.html">Instructor 1</a></li>
                                        <li><a href="team-two.html">Instructor 2</a></li>
                                        <li><a href="team-three.html">Instructor 3</a></li>
                                        <li><a href="team-details.html">Instructor Profile</a></li>
                                        <li><a href="faq.html">Faq's</a></li>
                                        <li><a href="404.html">404 Error</a></li>
                                        <li><a href="coming-soon.html">Coming Soon</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h6 className="menu-title">Inner Pages</h6>
                                    <ul className="submenu mega-sub-menu-01">
                                        <li><a href="gallery-grid.html">Gallery Grid</a></li>
                                        <li><a href="gallery-masonry.html">Gallery Masonry</a></li>
                                        <li><a href="event-grid.html">Event Grid</a></li>
                                        <li><a href="event-list.html">Event List</a></li>
                                        <li><a href="event-details.html">Event Details</a></li>
                                        <li><a href="pricing-table.html">Pricing Table</a></li>
                                        <li><a href="purchase-guide.html">Purchase Guide</a></li>
                                        <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                        <li><a href="terms-condition.html">Terms & Condition</a></li>
                                        <li><a href="my-account.html">Sign In</a></li>
                                    </ul>
                                </li>
                                <li>
                                    <h6 className="menu-title">Shop Pages</h6>
                                    <ul className="submenu mega-sub-menu-01">
                                        <li><a href="shop.html">Shop</a></li>
                                        <li><a href="product-details.html">Product Details</a></li>
                                        <li><a href="cart.html">Cart</a></li>
                                        <li><a href="wishlist.html">Wishlist</a></li>
                                        <li><a href="checkout.html">Checkout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li className="has-droupdown"><a href="#">Courses</a>
                            <ul className="submenu">
                                <li><a href="course-one.html">Course Style 1</a></li>
                                <li><a href="course-two.html">Course Style 2</a></li>
                                <li><a href="course-three.html">Course Style 3</a></li>
                                <li><a href="course-four.html">Course Style 4</a></li>
                                <li><a href="course-five.html">Course Style 5</a></li>
                                <li><a href="course-details.html">Course Details 1</a></li>
                                <li><a href="course-details-2.html">Course Details 2</a></li>
                                <li><a href="course-details-3.html">Course Details 3</a></li>
                                <li><a href="course-details-4.html">Course Details 4</a></li>
                                <li><a href="course-details-5.html">Course Details 5</a></li>
                                <li><a href="course-details-6.html">Course Details 6</a></li>
                            </ul>
                        </li>

                        <li className="has-droupdown"><a href="#">Blog</a>
                            <ul className="submenu">
                                <li><a href="blog-standard.html">Blog Standard</a></li>
                                <li><a href="blog-masonry.html">Blog Masonry</a></li>
                                <li><a href="blog-list.html">Blog List</a></li>
                                <li><a href="blog-details.html">Blog Details</a></li>
                            </ul>
                        </li>
                        <li className="has-droupdown"><a href="#">Contact</a>
                            <ul className="submenu">
                                <li><a href="contact-us.html">Contact Us</a></li>
                                <li><a href="contact-me.html">Contact Me</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            {/* <!-- Start Search Popup  --> */}
            <div className="edu-search-popup">
                <div className="content-wrap">
                    <div className="site-logo">
                        <img className="logo-light" src="assets/images/logo/logo-dark.png" alt="Corporate Logo"/>
                        <img className="logo-dark" src="assets/images/logo/logo-white.png" alt="Corporate Logo"/>
                    </div>
                    <div className="close-button">
                        <button className="close-trigger"><i className="icon-73"></i></button>
                    </div>
                    <div className="inner">
                        <form className="search-form" action="#">
                            <input type="text" className="edublink-search-popup-field" placeholder="Search Here..."/>
                            <button className="submit-button"><i className="icon-2"></i></button>
                        </form>
                    </div>
                </div>
            </div>
            {/* <!-- End Search Popup  --> */}
        </header>
    
    </>
  )
}

export default Header