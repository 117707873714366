import React from 'react';
import imgcourse from "../../assets/images/blog/blog-01.png";

function Coursecardlarge({courses, category}) {

  return (
    <>
{courses.map((course) => (
          <div key={course.pic} className="col-12 col-xl-4 col-lg-6 col-md-6">
            <div className="edu-course course-style-5 inline" data-tipped-options={`inline: 'inline-tooltip-1'`}>
              <div className="inner">
                <div className="thumbnail">
                  <a href={`/course-details/${course.courseurl}`}>
                    <img src={`${course.pic}`} alt="Course Meta"/>
                  </a>
                </div>
                <div className="content custom-content">
                  {/* <div className="course-price price-round"><i className='icon-17'></i> </div> */}
                  <a href={`/course-details/${course.courseurl}`} className="course-price price-round"><img src={imgcourse} style={{borderRadius: "50%"}} alt=''/></a>
                  <span className="course-level custom-badge">{category}</span>
                  <h5 className="title">
                    <a href={`/course-details/${course.courseurl}`}>{course.coursename}</a>
                  </h5>
                  <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5)</span>
                                    </div>
                  <div className="course-rating">
                    {/* <div className="rating">
                      {[...Array(Math.floor(course.rating))].map((_, i) => (
                        <i key={i} className="icon-23"></i>
                      ))}
                    </div> */}
                    {/* <span className="rating-count">({course.ratingCount})</span> */}
                  </div>
                  <p className='custom-paragraph1'>{course.metadescription}</p>
                  <ul className="course-meta">
                    <li><i className="icon-24"></i>{0} Batches</li>
                    <li><a className='fw-bold custom-padding' href={`/course-details/${course.courseurl}`}>View Course</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  )
}

export default Coursecardlarge;