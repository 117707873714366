import React from 'react'

function Categories() {
  return (
    <>
    <div className="edu-categorie-area categorie-area-2">
            {/* <div className="container">
                <div className="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <h2 className="title">Optional Courses</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                    <p style={{textAlign: "center"}}>Consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore</p>
                </div>

                <div className="row g-5">
                    <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-primary-style edublink-svg-animate">
                            <div className="icon">
                                <i className="icon-9"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Business Management</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-secondary-style">
                            <div className="icon">
                                <i className="icon-10 art-design"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Arts & Design</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra01-style">
                            <div className="icon">
                                <i className="icon-11 personal-development"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Personal Development</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-tertiary-style">
                            <div className="icon">
                                <i className="icon-12 health-fitness"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Health & Fitness</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra02-style">
                            <div className="icon">
                                <i className="icon-13 data-science"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Data Science</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra03-style">
                            <div className="icon">
                                <i className="icon-14"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Marketing</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="50" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra04-style">
                            <div className="icon">
                                <i className="icon-15"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Business & Finance</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra05-style">
                            <div className="icon">
                                <i className="icon-16 computer-science"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Computer Science</h5>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                        <div className="categorie-grid categorie-style-2 color-extra06-style">
                            <div className="icon">
                                <i className="icon-17 video-photography"></i>
                            </div>
                            <div className="content">
                                <a href="course-one.html">
                                    <h5 className="title">Video & Photography</h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </>
  )
}

export default Categories