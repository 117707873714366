import React from 'react';

function SingleNabar1() {
  return (
    <>
     <ul className="submenu custom-single-drop"> 
                                                <li><a href="course-one.html"><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; About Us</a></li>
                                                <li><a href="course-one.html"><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; Why SRIRAM's IAS</a></li>
                                                <li><a href="course-one.html"><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; Director's Message</a></li>
                                            </ul>
    
    
    
    
    
    
    
    </>
  )
}

export default SingleNabar1;