import React, { useEffect } from 'react';
import logoimg from "../../Logo-1.png";
import { useWebdetailsContext } from '../Context/WebDetails';

function Footer() {
    const { Webdetails, loading, error, fetchWebdetailsByUrlHandler } =
    useWebdetailsContext();

    useEffect(() => {
        fetchWebdetailsByUrlHandler(); // Fetch branches on component mount
      }, []);
    
      const Mailid = Webdetails?.adminemail || "Email Id";
      const phone = Webdetails?.callnumber || "";
      const flink = Webdetails?.facebook || "";
      const ilink = Webdetails?.instagram || "";
      const tlink = Webdetails?.twitter || "";
      const ylink = Webdetails?.youtube || "";


  return (
    <>
    <footer className="edu-footer footer-dark bg-image footer-style-2">
            <div className="footer-top">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6">
                            <div className="edu-footer-widget">
                                <div className="logo">
                                    <a href="index.html">
                                        <img className="logo-dark" src={logoimg} alt="Corporate Logo"/>
                                    </a>
                                </div>
                                <p className="description" style={{textAlign: "justify"}}>Founded nearly four decades ago by SRIRAM Sir, SRIRAM’s IAS has established itself as one of India’s premier institutes for Civil Services preparation.</p>
                                <div className="widget-information">
                                    <ul className="information-list">
                                        <li style={{textAlign: "justify"}}><span>Add:</span>SRIRAM's IAS TOWERS, 10-B, Pusa Road, Bada Bazaar Marg, Old Rajinder Nagar, New Delhi - 110060</li>
                                        <li><span>Call:</span><a href="tel:+91 - 9811489560">+91 - {phone}</a></li>
                                        <li><span>Email:</span><a href="mailto:sriramsias@gmail.com" target="_blank">{Mailid}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6">
                            <div className="edu-footer-widget explore-widget">
                                <h4 className="widget-title">Website Link</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><a href="/Braches">Branches</a></li>
                                        <li><a href="/Aboutus">About Us</a></li>
                                        <li><a href="#">Why SRIRAM's IAS</a></li>
                                        <li><a href="#">Director's Message</a></li>
                                        <li><a href="/course">Courses</a></li>
                                        <li><a href="#">Optional Courses</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                            <div className="edu-footer-widget quick-link-widget">
                                <h4 className="widget-title">Website Link</h4>
                                <div className="inner">
                                    <ul className="footer-link link-hover">
                                        <li><a href="contact-us.html">Contact Us</a></li>
                                        <li><a href="gallery-grid.html">Gallery</a></li>
                                        <li><a href="blog-standard.html">News & Articles</a></li>
                                        <li><a href="faq.html">FAQ's</a></li>
                                        <li><a href="my-account.html">Sign In/Registration</a></li>
                                        <li><a href="coming-soon.html">Coming Soon</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="edu-footer-widget">
                                <h4 className="widget-title">Contact Us</h4>
                                <div className="inner">
                                    <p className="description">Enter your email address to register to our newsletter subscription</p>
                                    <div className="input-group d-grid footer-subscription-form">
                                        <input type="email" className="form-control w-100 px-3 py-4 mb-2" placeholder="Your email"/>
                                        <button className="edu-btn edu-btn1 btn-medium" type="button" style={{marginLeft: "0px"}}>Subscribe <i className="icon-4"></i></button>
                                    </div>
                                    <ul className="social-share">
                                        <li><a href={`${flink}`} className="color-fb custom-icon-design"><i className="icon-facebook"></i></a></li>
                                        {/* <li><a href={`${flink}`} className="color-linkd custom-icon-design"><i className="icon-linkedin2"></i></a></li> */}
                                        <li><a href={`${ilink}`} className="color-ig custom-icon-design"><i className="icon-instagram"></i></a></li>
                                        <li><a href={`${tlink}`} className="color-twitter"><i className="icon-twitter"></i></a></li>
                                        <li><a href={`${ylink}`} className="color-yt custom-icon-design"><i className="icon-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner text-center">
                                <p>Copyright 2024 <a href="https://www.technologyxtend.com/" style={{color: "#db261f"}} target="_blank">SRIRAM's IAS</a> All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer;