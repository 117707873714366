import React from 'react';

function Registration() {
    return (
        <>
            <section className="edu-section-gap contact-form-area">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="contact-form">
                                <div className="section-title section-center">
                                    <h3 className="title">Register Now!</h3>
                                </div>
                                <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
                                    <span style={{ fontSize: "14px", fontWeight: "bold" }}>GENERAL DETAILS</span>
                                    <div className="row row--10">
                                        <div className="form-group col-lg-4">
                                            <input type="text" name="contact-name" id="contact-name" placeholder="Your Name" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <input type="email" name="contact-email" id="contact-email" placeholder="Your Email" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <input type="tel" name="contact-phone" id="contact-phone" placeholder="Phone number" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <input type="text" name="address" id="address" placeholder="Your Address" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <input type="text" name="state" id="state" placeholder="State" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <input type="text" name="city" id="city" placeholder="City" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <input type="number" name="pincode" id="pincode" placeholder="Pincode" />
                                        </div>
                                        <div className="form-group col-lg-4">
                                            <label class="custum-file-upload" for="file">
                                                <div class="text">
                                                    <span>Upload file</span>
                                                </div>
                                                <input type="file" id="file" />
                                            </label>
                                        </div>
                                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>CREATE YOUR LOGIN ID</span>
                                        <div className="form-group col-lg-6">
                                            <input type="text" name="login-id" id="login" placeholder="Login ID" />
                                        </div>
                                        <div className="form-group col-lg-6">
                                            <input type="password" name="password" id="password" placeholder="Password" />
                                        </div>
                                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Get your REFERAL CODE Now</span>
                                        <div className="form-group col-lg-12">
                                            <input type="text" name="referal-code" id="referal" placeholder="Referal Code" />
                                        </div>
                                        {/* <div className="form-group col-12">
                                        <textarea name="contact-message" id="contact-message" cols="30" rows="6" placeholder="Type your message"></textarea>
                                    </div> */}
                                        <div className="form-group col-12 text-center">
                                            <button className="rn-btn edu-btn submit-btn" name="submit" type="submit">Submit Now <i className="icon-4"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="shape-group">
                    <li className="shape-1 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape" /></li>
                    <li className="shape-2 scene"><img data-depth="2" src="assets/images/cta/shape-04.png" alt="shape" /></li>
                    <li className="shape-3 scene"><span data-depth="1"></span></li>
                    <li className="shape-4 scene"><img data-depth="-2" src="assets/images/about/shape-13.png" alt="shape" /></li>
                </ul>
            </section>
        </>
    )
}

export default Registration;