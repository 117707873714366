import "./App.css";
// import "./assets/fonts.css";
// import "./assets/App copy.css";
import Footer from "./Component/Footer/Footer";
import Header from "./Component/Header/Header";
import ScrollToTopSVG from "./Component/ScrollToTop/ScrollToTop";
import useEduJs from "./Hooks/useEduJs1";
import "./styles/marquee.scss";
import "./styles/upload.scss";
import "./styles/herocard.scss";
import "./styles/customdropdown.scss";
import "./styles/animatedbackground.scss";
import "./styles/calendar.scss";
// import "./styles/calender.scss";
import "./styles/common.scss";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import DisplayCourse from "./Component/DisplayCourse/DisplayCourse";
import Home from "./Component/Home/Home";
import Coursedetails from "./Component/Coursedetails/Coursedetails";
import Coming_Soon from "./Component/Coming Soon/Coming_Soon";
import Ourbranch from "./Component/Ourbranch/Ourbranch";
import DisplayBatch from "./Component/DisplayBatch/DisplayBatch";
import Registration from "./Component/Form/Registration";
import { ButtonDetailsProvider } from "./Component/Context/Pagebuttonmanagement";
import { BranchProvider } from "./Component/Context/Branchapi";
import { BackgroundProvider } from "./Component/Context/BackgroundChange";
import Blogs from "./Component/Blogs/Blogs";
import Articles from "./Component/Articles/Articles";
import { BlogsProvider } from "./Component/Context/Blogsapi";
import BlogDetails from "./Component/BlogDetails/BlogDetails1";
import { CourseProvider } from "./Component/Context/Courseapi";
import { ArticleProvider } from "./Component/Context/Articleapi";
import Datepicker from "./Component/Calender/Datepicker/Datepicker";
import ArticleDetails from "./Component/ArticleDetails/ArticlesDetails";
import BranchPage from "./Component/Pages/BranchPage";
import { DateProvider } from "./Component/Context/Dateapi";
import { WebdetailsProvider } from "./Component/Context/WebDetails";
import { NewsheadlineProvider } from "./Component/Context/NewsHeadline";
function App() {
  useEduJs();

  return (
    <>
    
        <WebdetailsProvider>
      <BackgroundProvider>
        <BrowserRouter>
          {/* <div id="edublink-preloader">
        <div className="loading-spinner">
        <div className="preloader-spin-1"></div>
        <div className="preloader-spin-2"></div>
        </div>
        <div className="preloader-close-btn-wraper">
        <span className="btn btn-primary preloader-close-btn">
        Cancel Preloader</span>
        </div>
        </div> */}
        <NewsheadlineProvider>
          <Header />
          </NewsheadlineProvider>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Braches" element={<BranchPage />} />
            {/* <Route path="/Course" element={
            <>
            <BackgroundProvider>
            <DisplayCourse />
            </BackgroundProvider>
            
            </>
            
            
            } /> */}
            {/* <Route path="/Course-details" element={
            
            <Coursedetails />
            
            } />  */}
            <Route
              path="/blog-details/:blogurl"
              element={
                <>
                <BackgroundProvider>
                <BlogsProvider>
                  <BlogDetails />
                  </BlogsProvider>
                </BackgroundProvider>
                </>
              }
            />
            <Route
              path="/upsc-daily-current-affairs/:articleurl"
              element={
                <>
                <BackgroundProvider>
                <ArticleProvider>
                  <ArticleDetails />
                  </ArticleProvider>
                </BackgroundProvider>
                </>
              }
            />
            <Route
              path="/articles"
              element={
                <>
                <DateProvider>
                  <BackgroundProvider>
                    <ArticleProvider>
                      <Articles />
                    </ArticleProvider>
                  </BackgroundProvider>
                  </DateProvider>
                </>
              }
            />
            <Route
              path="/blogs"
              element={
                <>
                <DateProvider>
                  <BackgroundProvider>
                    <BlogsProvider>
                      <Blogs /> 
                    </BlogsProvider>
                  </BackgroundProvider>
                  </DateProvider>
                </>
              }
            /> 
            {/* <Route path="/batch/:url" element={<Coursedetails />} /> */}
            <Route
              path="/Course"
              element={
                <CourseProvider>
                  <DisplayCourse />
                </CourseProvider>
              }
            />
            <Route
              path="/Course-details/:courseurl"
              element={
                <>
                  <ButtonDetailsProvider>
                    <BackgroundProvider>
                      <CourseProvider>
                        <Coursedetails />
                      </CourseProvider>
                    </BackgroundProvider>
                  </ButtonDetailsProvider>
                </>
              }
            />
            <Route
              path="/branch/:branchUrl"
              element={
                <>
                  <BackgroundProvider>
                    <BranchProvider>
                      <Ourbranch />
                    </BranchProvider>
                  </BackgroundProvider>
                </>
              }
            />
            <Route
              path="/batch-details"
              element={
                <>
                  <ButtonDetailsProvider>
                    <DisplayBatch />
                  </ButtonDetailsProvider>
                </>
              }
            />
            <Route path="/*" element={<Coming_Soon />} />
            <Route
              path="/our-branch"
              element={
                <>
                  <BranchProvider>
                    <Ourbranch />
                  </BranchProvider>
                </>
              }
            />
            <Route path="/registration" element={<Registration />} />
            {/* <Route path="/date" element={<Datepicker />} /> */}
          </Routes>
          <Footer />
          <ScrollToTopSVG />
        </BrowserRouter>
        
      </BackgroundProvider>
        </WebdetailsProvider>

    </>
  );
}

export default App;
