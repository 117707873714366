import React from 'react';
import AllSriCourse from '../AllSriCourse/AllSriCourse';
import Allcoursecard from '../Courses/Allcoursecard';

function DisplayCourse() {
  return (
    <>
    <AllSriCourse/>
    <Allcoursecard/>
    </>
  )
}

export default DisplayCourse;