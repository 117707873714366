import React, { useEffect } from "react";
import courseimg from "../../assets/images/course/course-111.jpg";
import imgcourse from "../../assets/images/blog/blog-01.png";
import { useCourseContext } from "../Context/Courseapi";

function Branch() {
   const {courses, loading, error, fetchAllCoursesHandler} = useCourseContext();

   useEffect(() => {
    fetchAllCoursesHandler(); // Fetch branches on component mount
  }, []);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <div
        className="section-title section-center"
      >
        <span className="pre-title">Popular Course</span>
        <h2 className="title">Pick A Course To Get Started</h2>
        <span className="shape-line">
          <i className="icon-19"></i>
        </span>
      </div>
      <div className="row g-5">
      {courses.map((course) => (
          <div key={course.pic} className="col-12 col-xl-4 col-lg-6 col-md-6">
            <div className="edu-course course-style-5 inline" data-tipped-options={`inline: 'inline-tooltip-1'`}>
              <div className="inner">
                <div className="thumbnail">
                  <a href={`/course-details/${course.courseurl}`}>
                    <img src={`${course.pic}`} alt="Course Meta"/>
                  </a>
                </div>
                <div className="content custom-content">
                <a href={`/course-details/${course.courseurl}`} className="course-price price-round"><img src={imgcourse} style={{borderRadius: "50%"}} alt=''/></a>
                  <span className="course-level custom-badge">Engineering</span>
                  <h5 className="title">
                    <a href={`/course-details/${course.courseurl}`}>{course.coursename}</a>
                  </h5>
                  <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i> 
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(5)</span>
                                    </div>
                  <div className="course-rating">
                    {/* <div className="rating">
                      {[...Array(Math.floor(course.rating))].map((_, i) => (
                        <i key={i} className="icon-23"></i>
                      ))}
                    </div> */}
                    {/* <span className="rating-count">({course.ratingCount})</span> */}
                  </div>
                  <p>{course.metadescription}</p>
                  <ul className="course-meta">
                    <li><i className="icon-24"></i>8 Lessons</li>
                    <li><a className='fw-bold custom-padding' href={`/course-details/${course.courseurl}`}>View Course</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Branch;
