import React, { useEffect } from 'react';
import { useButtonDetailsContext } from '../Context/Pagebuttonmanagement';

function ButtonDetails({DetailscomponentName}) {
    const { buttonDetailsNames, activeDetailsButton, updateButtonDetailsNames , handleDetailsButtonClick, hasDetailUpdated, setHasDetailUpdated } = useButtonDetailsContext();

    useEffect(() => {
        if(!hasDetailUpdated){
            updateButtonDetailsNames(DetailscomponentName); // Update button names based on componentName
            setHasDetailUpdated(true)
        }
    }, [DetailscomponentName, updateButtonDetailsNames]);
  return (
    <>
    {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="overview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#overview"
                      type="button"
                      role="tab"
                      aria-controls="overview"
                      aria-selected="false"
                    >
                      Our Courses
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="overview-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#overview"
                      type="button"
                      role="tab"
                      aria-controls="overview"
                      aria-selected="false"
                    >
                      Overview
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="carriculam-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#carriculam"
                      type="button"
                      role="tab"
                      aria-controls="carriculam"
                      aria-selected="true"
                    >
                      Carriculam
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="instructor-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#instructor"
                      type="button"
                      role="tab"
                      aria-controls="instructor"
                      aria-selected="false"
                    >
                      Instructor
                    </button>
                  </li>

                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="review-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#review"
                      type="button"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      Reviews
                    </button>
                  </li>
                </ul>
     */}

     

     

<ul className="nav nav-tabs" id="myTab" role="tablist">
      {buttonDetailsNames.map((button) => (
        <li className="nav-item" role="presentation" key={button.id}>
          <button
            className={`nav-link ${activeDetailsButton === button.label ? 'active' : ''}`}
            id={button.id}
            // data-bs-toggle="tab"
            // data-bs-target={button.target}
            // type="button"
            // role="tab"
            // aria-controls={button.label.toLowerCase()}
            aria-selected={activeDetailsButton === button.label ? 'true' : 'false'}
            onClick={() => handleDetailsButtonClick(button.label)}
          >
            {button.label}
          </button>
        </li>
      ))}
    </ul>
    </>
  )
}

export default ButtonDetails;