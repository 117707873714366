import React from 'react';
import { useDateContext } from '../../Context/Dateapi';

function Datepicker({isvalidblogs, isvalidarticles}) { 
    const { startdate, enddate, loading, error, handleStartDateChange, handleEndDateChange, handleSubmitBlog, handleSubmitarticle } =
    useDateContext();
  return (
    <>
    <div className="container-fluid">
                                                <form className="comment-form pb-5">
                                                    <div className="row g-5">
                                                        <div className="form-group col-lg-5">
                                                            <h6>Start Date :</h6>
                                                            <input style={{fontSize:"14px", textTransform: "uppercase", border: "0px solid black", boxShadow: "0 1px 10px rgba(0,0,0,0.2)", padding: "0.5vmax 2vmax", height:"50px"}} type="date" value={startdate}
              onChange={handleStartDateChange}
 name="Start-Date" id="" placeholder="Start Date" />
                                                        </div>
                                                        <div className="form-group col-lg-5">
                                                            <h6>End Date :</h6>
                                                            <input style={{fontSize:"14px", textTransform: "uppercase", border: "0px solid black", boxShadow: "0 1px 10px rgba(0,0,0,0.2)", padding: "0.5vmax 2vmax", height:"50px"}} value={enddate}
              onChange={handleEndDateChange}
 type="date" name="End-Date" id="" placeholder="End Date" />
                                                        </div>
                                                        <div className="form-group col-2 d-flex align-items-end">
                                                            <button style={{height:"50px", display:"flex", alignItems:"center"}}   onClick={(e) => {
        e.preventDefault(); // Prevent form submission
        if (isvalidblogs) {
            handleSubmitBlog(e, startdate, enddate);
        } 

        if(isvalidarticles) {
            handleSubmitarticle(e, startdate, enddate);
        }
    }}  type="submit" style={{paddingLeft:"22px"}} className="edu-btn submit-btn">Apply</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
    
    
    </>
  )
}

export default Datepicker;