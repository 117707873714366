import React from "react";
import queryimg from "../../assets/images/query-img.png";

function CustomEnquery() {
  return (
    <>
    
    <div className="col-lg-12 mt-5">
                        {/* <div className="contact-form form-style-2 px-5 py-5 animated-gradient-background"> */}
                        <div className="contact-form form-style-2 px-0 pt-0 py-5 animated-gradient-background1" style={{border:"1px solid #301C6F"}}>
                            <div className="section-title" style={{background:"#301C6F"}}>
                                <h4 className="title text-center text-white animated-gradient-background w-100 py-4">
                                  <h4 style={{color:"white"}}>Have A Query ?</h4>
                                  {/* <img  src={queryimg} alt="" style={{width:"180px"}}/> */}
                                  {/* <img  src={queryimg} style={{mixBlendMode: 'color-burn', width: "180px"}} alt=""/> */}
                                  
                                  {/* Have A Query! */}
                                  
                                  </h4>
                                <p className="px-5 text-white">Got Questions? We're Here to Help—Reach Out Today!</p>
                            </div>
                            <div
        className="form-wrapper rnt-contact-form rwt-dynamic-form px-5"
        id="signin"
      >
        <form name="form1" className="rnt-contact-form rwt-dynamic-form" action="verify.php" method="post">
          <div className="d-flex justify-content-center">
            {/* <img src={query} style={{ width: "60%" }} /> */}
          </div>
          <form className="form-floating my-3 ">
            <input
              type="text"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", border: "1px solid #ccc" }}
              // style={{ fontSize: "14px", border: "1px solid rgba(255,255,255,0.2)" }}
              name="name"
              id="floatingInputname"
              placeholder="Name"
            />
            {/* <input
              type="text"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", color: "white", border: "2px solid rgba(255,255,255,0.5)", background: "transparent" }}
              name="name"
              id="floatingInputname"
              placeholder="Name"
            /> */}
            <label className="fs-4" for="floatingInputname"><i className="icon-6"></i> Name</label>
          </form>

          <form className="form-floating  my-3">
            <input
              type="email"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", border: "1px solid #ccc"}}
              // style={{ fontSize: "14px", border: "2px solid rgba(0,0,0,0.5)"}}
              name="email"
              id="floatingInputnumber"
              placeholder="Email"
            />
            <label for="floatingInputnumber" className="fs-4"><i className="icon-envelope"></i> Email</label>
          </form>
          <form className="form-floating my-3">
            <input
              type="number"
              className="form-control py-5 pb-4"
              style={{ fontSize: "14px", border: "1px solid #ccc"}}
              // style={{ fontSize: "14px", border: "2px solid rgba(0,0,0,0.5)"}}
              name="contact"
              id="floatingInputemail"
              placeholder="Mobile No"
            />
            <label for="floatingInputemail" className="fs-4"><i className="icon-phone"></i> Mobile No</label>
          </form>
          <div className="form-floating my-3">
            <textarea
              rows={5}
              cols={30}
              className="form-control py-5 pb-5"
              name="query"
              style={{ fontSize: "14px", minHeight: "100px", border: "1px solid #ccc" }}
              placeholder="Leave a comment here"
              id="floatingTextarea"
            ></textarea>
            <label for="floatingTextarea" className="fs-4"><i className="icon-81"></i> Write a Query Here</label>
          </div>
                                              <div className="col-12 text-center">
                                        <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit <i className="icon-4"></i></button>
                                    </div>
        </form>
      </div>
                            {/* <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul> */}
                        </div>
                    </div>
    </>
  );
}

export default CustomEnquery;
