import React from 'react';

function ArticleCard({articles}) {
  return (
    <>
      {articles.map((article, index) => (
      <a href={`/upsc-daily-current-affairs/${article.url}`} className="testimonial-slide testimonial-style-2" key={index}>
                                <div className="content w-100" style={{borderRadius:"7px", padding:"55px"}}>
                                        <div className="info d-flex justify-content-space-between w-100">
                                            <h6 style={{color:"#301C6F", textTransform: "uppercase", marginBottom:"1.5rem", lineHeight:"1.8"}} className="title"><i class="icon-20 text-success"></i>&nbsp;{article.title}</h6>
                                        </div>
                                    <div style={{padding: "10px", backgroundColor: "#fbfbfb", borderRadius:"5px"}}>
                                       <p>{article.metadescription}</p>
                                    </div>
                                    <div style={{float:"right", paddingTop:"10px"}}>
                                        <p style={{marginBottom:"1.5rem", width:"73px"}} className="title text-success custom-badge1"><i class="icon-27"></i>&nbsp;{article.articlepostdate}</p>
                                    </div>
                                </div>
                            </a>
))}
    </>
  )
}

export default ArticleCard;
