import React from 'react';

function DoubleNavbar() {
  return (
    <>
    
    <ul className="mega-menu">
                                            <li>
                                                <h6 className="menu-title">Inner Pages</h6>
                                                <ul className="submenu mega-sub-menu-01">
                                                    <li><a href="about-one.html">About Us 1</a></li>
                                                    <li><a href="about-two.html">About Us 2</a></li>
                                                    <li><a href="about-three.html">About Us 3</a></li>
                                                    <li><a href="team-one.html">Instructor 1</a></li>
                                                    <li><a href="team-two.html">Instructor 2</a></li>
                                                    <li><a href="team-three.html">Instructor 3</a></li>
                                                    <li><a href="team-details.html">Instructor Profile</a></li>
                                                    <li><a href="faq.html">Faq's</a></li>
                                                    <li><a href="404.html">404 Error</a></li>
                                                    <li><a href="coming-soon.html">Coming Soon</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className="menu-title">Inner Pages</h6>
                                                <ul className="submenu mega-sub-menu-01">
                                                    <li><a href="gallery-grid.html">Gallery Grid</a></li>
                                                    <li><a href="gallery-masonry.html">Gallery Masonry</a></li>
                                                    <li><a href="event-grid.html">Event Grid</a></li>
                                                    <li><a href="event-list.html">Event List</a></li>
                                                    <li><a href="event-details.html">Event Details</a></li>
                                                    <li><a href="pricing-table.html">Pricing Table</a></li>
                                                    <li><a href="purchase-guide.html">Purchase Guide</a></li>
                                                    <li><a href="privacy-policy.html">Privacy Policy</a></li>
                                                    <li><a href="terms-condition.html">Terms & Condition</a></li>
                                                    <li><a href="my-account.html">Sign In</a></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <h6 className="menu-title">Shop Pages</h6>
                                                <ul className="submenu mega-sub-menu-01">
                                                    <li><a href="shop.html">Shop</a></li>
                                                    <li><a href="product-details.html">Product Details</a></li>
                                                    <li><a href="cart.html">Cart</a></li>
                                                    <li><a href="wishlist.html">Wishlist</a></li>
                                                    <li><a href="checkout.html">Checkout</a></li>
                                                </ul>
                                            </li>
                                        </ul>
    
    
    
    
    </>
  )
}

export default DoubleNavbar;