import React from 'react'
import h1 from "../../assets/images/animated-svg-icons/online-class.svg"
import h2 from "../../assets/images/animated-svg-icons/instructor.svg"
import h3 from "../../assets/images/animated-svg-icons/certificate.svg"
import h4 from "../../assets/images/animated-svg-icons/user.svg"


function Highlights() {
  return (
    <>
    <div className="features-area-2">
            <div className="container">
                <div className="features-grid-wrap">
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src={h1} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Live Classes</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src={h4} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Interview</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon certificate">
                            <img className="svgInject" src={h3} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Daily Quiz</h5>
                        </div>
                    </div>
                    <div className="features-box features-style-2 edublink-svg-animate">
                        <div className="icon">
                            <img className="svgInject" src={h2} alt="animated icon"/>
                        </div>
                        <div className="content">
                            <h5 className="title">Test Series</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Highlights