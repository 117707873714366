import { createContext, useState, useContext } from 'react';
import { fetchAllArticles, fetchArticlesByUrl } from '../../Config/api';

const ArticleContext = createContext();

export function ArticleProvider({ children }) {
    const [articles, setArticles] = useState([]);
    const [articleDetails, setArticleDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all branches data
      const fetchAllArticlesHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllArticles();
            setArticles(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

    // Fetch single article data based on URL 
    const fetchArticleByUrlHandler = async (articleUrl) => {
        setLoading(true);
        try {
            const data = await fetchArticlesByUrl(articleUrl);
            setArticleDetails(data.data[0])
            console.log(data)
            // return data.data; // Return branch data from response
        } catch (err) {
            console.error("Error fetching branch by URL:", err);
            setError("Failed to load branch by URL");
            return null;
        } finally {
            setLoading(false);
        }
    }; 
  
    return (
      <ArticleContext.Provider value={{ articles, articleDetails, loading, error, fetchAllArticlesHandler , fetchArticleByUrlHandler }}>
        {children}
      </ArticleContext.Provider>
    );
  }
  
  export function useArticleContext() {
    return useContext(ArticleContext);
  }