import React, { useEffect } from "react";
// import "../../fonts.css"
// import "../../App.css";
import Courses from "../Card/Coursescard";
import Enquery from "../Form/Enquery";
import { useBlocker, useParams } from "react-router-dom";
import { useBranchContext } from "../Context/Branchapi";
import img1 from "../../assets/images/others/shape-81.png";
import img2 from "../../assets/images/others/shape-65.png";
import img3 from "../../assets/images/cta/shape-08.png";
import img4 from "../../assets/images/others/shape-79.png";
import img5 from "../../assets/images/course/course-12.jpg";
import img6 from "../../assets/images/course/course-46.png";
import img7 from "../../assets/images/svg-icons/books.svg";
import { useBackgroundContext } from "../Context/BackgroundChange";
import Coursecardlarge from "../Card/Coursecardlarge";
import Branch from "../Courses/Branch";
import CustomEnquery from "../Form/CustomEnquery";
import { useBlogsContext } from "../Context/Blogsapi";

// import useEduJs from '../../Hooks/useEduJs1';

function Blogdetails1() {
  // useEduJs();

  const { blogurl } = useParams(); // Extracts branchUrl from the URL
  const { backgroundStyle } = useBackgroundContext();
  const { blogDetails, loading, error, fetchBlogByUrlHandler } =
    useBlogsContext();

  useEffect(() => {
    fetchBlogByUrlHandler(blogurl); // Fetch branches on component mount
  }, [blogurl]);

  if (loading) return <p>Loading...</p>;

  // Display the branch information if data is available
  // const branch = branches.find(b => b.url === branchUrl);

  const branchName = blogDetails?.title || "Blog Name";
  const addressHTML = blogDetails?.blog || "";

  return (
    <>
      <div className="edu-breadcrumb-area breadcrumb-style-8">
        <div className="breadcrumb-right-wrapper" style={backgroundStyle}>
          <div className="container">
            <div className="breadcrumb-inner">
              <div className="page-title">
                <h2 className="title">{branchName}</h2>
              </div>
              <ul className="course-meta">
                <li>
                  <i className="icon-58"></i>by SRIRAM's IAS
                </li>
                {/* <li>
                  <i className="icon-59"></i>English
                </li> */}
                {/* <li className="course-rating">
                  <div className="rating">
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                    <i className="icon-23"></i>
                  </div>
                  <span className="rating-count">(720 Rating)</span>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <ul className="shape-group">
          <li className="shape-6 scene">
            <img data-depth="2" src={img1} alt="shape" />
          </li>
          <li className="shape-7 scene">
            <img data-depth="-2" src={img2} alt="shape" />
          </li>
          <li className="shape-8">
            <img src={img3} alt="shape" />
          </li>
          <li className="shape-9 scene">
            <img data-depth="-2" src={img4} alt="shape" />
          </li>
        </ul>
      </div>
      <section className="edu-section-gap course-details-area">
        <div className="container">
          <div className="row row--30">
            <div className="col-lg-8">
              <div className="course-details-content course-details-7">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="overview"
                    role="tabpanel"
                    aria-labelledby="overview-tab"
                  >
                    <div className="course-tab-content">
                      <div className="course-overview">
                        <div className="course-tab-content">
                          <div className="course-overview">
                            <h3 className="heading-title">Address</h3>
                            <p
                              dangerouslySetInnerHTML={{ __html: addressHTML }}
                            />
                            <p className="">
                              Excepteur sint occaecat cupidatat non proident
                              sunt in culpa qui officia deserunt mollit anim id
                              est laborum. Sed ut perspiciatis unde omnis iste
                              natus error sit voluptatem accusantium doloremque
                              laudantium totam rem aperiam.
                            </p>
                            <p className="mb--60">
                              Excepteur sint occaecat cupidatat non proident
                              sunt in culpa qui officia deserunt mollit anim id
                              est laborum. Sed ut perspiciatis unde omnis iste
                              natus error sit voluptatem accusantium doloremque
                              laudantium totam rem aperiam.
                            </p>
                            <h5 className="title">What You’ll Learn?</h5>
                            <ul className="mb--60">
                              <li>
                                Learn to use Python professionally, learning
                                both Python 2 & Python 3!
                              </li>
                              <li>
                                Build 6 beautiful real-world projects for your
                                portfolio (not boring toy apps)
                              </li>
                              <li>
                                Understand the Theory behind Vue.js and use it
                                in Real Projects
                              </li>
                              <li>
                                Create responsive, accessible, and beautiful
                                layouts
                              </li>
                            </ul>
                            <p>
                              Consectetur adipisicing elit, sed do eiusmod
                              tempor inc idid unt ut labore et dolore magna
                              aliqua enim ad minim veniam quis nostrud exerec
                              tation ullamco laboris nis aliquip commodo
                              consequat duis aute irure dolor.
                            </p>
                          </div>
                          <div className="course-overview">
                            {/* <div className="row g-5">
                            <Coursecardlarge/>
                            <Coursecardlarge/>
                            <Coursecardlarge/>
                            <Coursecardlarge/>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="course-sidebar-3 sidebar-top-position">
                <div className="edu-course-widget widget-course-summery">
                  <div className="inner">
                    <div className="thumbnail">
                      <img src={img6} alt="Courses" />
                      {/* <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" className="play-btn video-popup-activation"><i className="icon-18"></i></a> */}
                    </div>
                    <div className="content">
                      <h4 className="widget-title">{branchName} :</h4>
                      <ul className="course-item">
                        {/* <li>
                          <span className="label">
                            <i className="icon-60"></i>Price:
                          </span>
                          <span className="value price">$70.00</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-62"></i>Instrutor:
                          </span>
                          <span className="value">Edward Norton</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-61"></i>Duration:
                          </span>
                          <span className="value">3 weeks</span>
                        </li> */}
                        <li>
                          <span className="label">
                            <img
                              className="svgInject"
                              src={img7}
                              alt="book icon"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;No of Batches:
                          </span>
                          <span className="value">0</span>
                        </li>
                        <li>
                          <span className="label">
                            <img
                              className="svgInject"
                              src={img7}
                              alt="book icon"
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;No of Courses:
                          </span>
                          <span className="value">0</span>
                        </li>
                        {/* <li>
                          <span className="label">
                            <i className="icon-63"></i>Enrolled:
                          </span>
                          <span className="value">65 students</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-59"></i>Language:
                          </span>
                          <span className="value">English</span>
                        </li> */}
                        {/* <li>
                          <span className="label">
                            <i className="icon-64"></i>Certificate:
                          </span>
                          <span className="value">Yes</span>
                        </li> */}
                      </ul>
                      {/* <div className="read-more-btn">
                                            <a href="#" className="edu-btn">Start Now <i className="icon-4"></i></a>
                                        </div> */}
                      {/* <div className="share-area">
                                            <h4 className="title">Share On:</h4>
                                            <ul className="social-share">
                                                <li><a href="#"><i className="icon-facebook"></i></a></li>
                                                <li><a href="#"><i className="icon-twitter"></i></a></li>
                                                <li><a href="#"><i className="icon-linkedin2"></i></a></li>
                                                <li><a href="#"><i className="icon-youtube"></i></a></li>
                                            </ul>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <Enquery /> */}
              <CustomEnquery />
            </div>
          </div>
        </div>
      </section>
      <div className="gap-bottom-equal" style={{ paddingBottom: "5px" }}>
        {/* <div className="edu-course-area course-area-1 gap-tb-text">
          <div className="container">
            <div className="row g-5">
              <Coursecardlarge />
              <Coursecardlarge />
              <Coursecardlarge />
              <Coursecardlarge />
            </div>
          </div>
        </div> */}
        {/* <Branch/> */}
        {/* <div className="container"> */}
        {/* <div className="section-title section-left" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                    <h3 className="title">More Courses for You</h3>
                </div> */}
      </div>
    </>
  );
}

export default Blogdetails1;
