import React from 'react';
import turl from "../../assets/images/team/team-01/team-03.png" 

function Team({ instructor, image_location_path='01' }) {
  return (
    <>
    <div className="edu-team-grid team-style-1">
                            <div className="inner">
                                <div className="thumbnail-wrap">
                                    <div className="thumbnail">
                                        <a href={`/team-details/${instructor.id}`}>
                                            <img src={turl} alt="team images"/>
                                        </a>
                                    </div>
                                    <ul className="team-share-info">
                                        <li><a href="#"><i className="icon-share-alt"></i></a></li>
                                        {instructor.social_links.map((social, i) => (
                            <li key={i}>
                                <a href={social.link} target={social.target ? social.target : ''}>
                                    <i className={social.icon}></i>
                                </a>
                            </li>
                        ))}
                                    </ul>
                                </div>
                                <div className="content">
                                    <h5 className="title"><a href={`/team-details/${instructor.id}`}>{instructor.name}</a></h5>
                                    <span className="designation">{instructor.title}</span>
                                </div>
                            </div>
                        </div>
    </>
  )
}

export default Team;