import React from 'react';
import Batch from '../Batch/Batch';
import Branch from './Branch';

function Allcoursecard() {
  return (
    <>
    <div className="edu-course-area course-area-1 gap-tb-text bg-lightenharsh">
            <div className="container">


                {/* <div className="edu-sorting-area">
                    <div className="sorting-left">
                        <h6 className="showing-text">We found <span>71</span> courses available for you</h6>
                    </div>
                    <div className="sorting-right">
                        <div className="layout-switcher">
                            <label>Grid</label>
                            <ul className="switcher-btn">
                                <li><a href="course-one.html" className="active"><i className="icon-53"></i></a></li>
                                <li><a href="course-four.html" className=""><i className="icon-54"></i></a></li>
                            </ul>
                        </div>
                        <div className="edu-sorting">
                            <div className="icon"><i className="icon-55"></i></div>
                            <select className="edu-select">
                                <option>Filters</option>
                                <option>Low To High</option>
                                <option>High Low To</option>
                                <option>Last Viewed</option>
                            </select>
                        </div>
                    </div>
                </div> */}
     
            <Branch/>
                <div className="load-more-btn" data-sal-delay="100" data-sal="slide-up" data-sal-duration="1200">
                    <a href="course-one.html" className="edu-btn">Load More <i className="icon-56"></i></a>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default Allcoursecard;