import React from 'react';

function Enquery() {
  return (
    <>
    
    <div className="col-lg-12 mt-5">
                        <div className="contact-form form-style-2 px-5 pt-5">
                            <div className="section-title">
                                <h4 className="title text-center">Have A Query!</h4>
                                <p>Got Questions? We're Here to Help—Reach Out Today!</p>
                            </div>
                            <form className="rnt-contact-form rwt-dynamic-form" id="contact-form" method="POST" action="mail.php">
                                <div className="row row--10">
                                    <div className="form-group form-floating col-12 mt-1">
                                    <input type="text" name="contact-name" className="p-5 py-2 px-0 mt-5" id="contact-name"/>
                                    <label className='fs-4' for="contact-message"><i className="icon-6"></i> Your Name</label>
                                    </div>
                                    <div className="form-group form-floating col-12 mt-1">
                                        <input type="tel" name="contact-phone" className="p-5 py-2 px-0 mt-5" id="contact-phone"/>
                                        <label className='fs-4' for="contact-message"><i className="icon-phone"></i> Your Contact</label>
                                    </div>
                                    <div className="form-group form-floating col-12 mt-2">
                                        <input type="email" name="contact-email" className="p-5 px-0 mt-5" id="contact-email"/>
                                        <label className='fs-4' for="contact-message"><i className="icon-envelope"></i> Your Email</label>
                                    </div>
                                    <div className="form-group form-floating col-12 mt-2">
                                    {/* <i className="icon-81"></i> */}
                                        <textarea name="contact-message" className="p-5 px-0 mt-5" id="contact-message" cols="30" rows="1"></textarea>
                                        <label className='fs-4' for="contact-message"><i className="icon-81"></i> Your message</label>
                                    </div>
                                    <div className="form-group col-12 text-center">
                                        <button className="rn-btn edu-btn btn-medium submit-btn" name="submit" type="submit">Submit <i className="icon-4"></i></button>
                                    </div>
                                </div>
                            </form>
                            {/* <ul className="shape-group">
                                <li className="shape-1 scene"><img data-depth="1" src="assets/images/about/shape-13.png" alt="Shape"/></li>
                                <li className="shape-2 scene"><img data-depth="-1" src="assets/images/counterup/shape-02.png" alt="Shape"/></li>
                            </ul> */}
                        </div>
                    </div>
    
    
    </>
  )
}

export default Enquery;