import { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

const ButtonContext = createContext();

export function ButtonProvider({ children }) {
    const [buttonNames, setButtonNames] = useState([]);
    const [activeButton, setactiveButton] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate

     // Function to update button names based on componentName
     const updateButtonNames = (componentName) => {
        const buttonLabels = {
            'sriCourse': ['Old Rajinder Nagar', "Mukherjee Nagar", "Pune","Hyderabad"],
            'sriCourse1': ['CSAT', '2 Years G.S Course', 'Mains Mastery Course 2025', '1 Year G.S Course', 'Optional Subject Course', 'G.S Module Course'],
            'dashboard': ['Settings', 'Security', 'My Courses', 'Subscribe More Courses', 'My Test Series', 'Subscribe More Test Series'],
            'CourseDescriptionprelimstest': ['Class Questions', 'Prelims', 'Teacher Support', 'Upload Answers'],
            'CourseDescription': ['Class Questions', 'Teacher Support', 'Upload Answers'],
            'PrelimsDescription': ['Prelims', 'Teacher Support', 'Upload Answers'],
            'WithoutPrelimsDescription': ['Teacher Support', 'Upload Answers']
        };


        const defaultButtons = buttonLabels['sriAboutUs']; // Fallback
        const buttons = buttonLabels[componentName] || defaultButtons;
        setButtonNames(buttons); 

        // Set default active button based on the component name
        switch (componentName) {
            case 'civilHistory':
            case 'civilTest':
            case 'CourseDescriptionprelimstest':
            case 'sriAboutUs':
                setactiveButton(buttons[0].toLowerCase().replace(/\s+/g, '')); // Default to the second button
                break;
            case 'sriCourse':
                setactiveButton(''); // Default to the second button
                break;
            case 'CourseDescription':
            case 'WithoutPrelimsDescription':
                setactiveButton(buttons[0].toLowerCase().replace(/\s+/g, '')); // Default to the first button
                break;
            case 'dashboard':
                setactiveButton(buttons[0].toLowerCase().replace(/\s+/g, '')); // Default to the first button
                break;
            default:
                setactiveButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
        }
    };

    // Function to handle setting the active button
    const handleButtonClick = (name) => {
        setactiveButton(name.toLowerCase().replace(/\s+/g, ''));
        switch (name) {
            case 'Old Rajinder Nagar':
                // Handle Teacher Support accordingly
                navigate('/branch/old-rajinder-nagar'); // Navigate to /about-us when About Us is clicked
                break;
            case "Mukherjee Nagar":
                // Handle Upload Answers accordingly
                navigate('/branch/mukherjee-nagar'); // Navigate to /about-us when About Us is clicked
                break;
            case "Pune":
                // Handle Upload Answers accordingly
                navigate('/branch/pune'); // Navigate to /about-us when About Us is clicked
                break;
            case "Hyderabad":
                // Handle Upload Answers accordingly
                navigate('/branch/hyderabad'); // Navigate to /about-us when About Us is clicked
                break;
            default:
                break;
        }
    };
  
    return (
      <ButtonContext.Provider value={{ buttonNames, activeButton, updateButtonNames, handleButtonClick  }}>
        {children}
      </ButtonContext.Provider>
    );
  }
  
  export function useButtonContext() {
    return useContext(ButtonContext);
  }