import { createContext, useState, useContext } from 'react';
import { fetchAllCourses, fetchCourseByUrl } from '../../Config/api';

const CourseContext = createContext();

export function CourseProvider({ children }) {
    const [courses, setCourses] = useState([]);
    const [coursesDetails, setCoursesDetails] = useState({});
    const [BatchbyCourse, setBatchbyCourse] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all branches data
      const fetchAllCoursesHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllCourses();
            setCourses(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    }; 

        // Fetch single branch data based on URL 
        const fetchCourseByUrlHandler = async (courseurl) => {
          console.log(courseurl+ "in branch conext api")
          setLoading(true);
          try {
              const data = await fetchCourseByUrl(courseurl);
              setCoursesDetails(data.data.course)
              setBatchbyCourse(data.data.batches)
              console.log(data)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      };
  
    return (
      <CourseContext.Provider value={{ courses, coursesDetails, BatchbyCourse, loading, error, fetchAllCoursesHandler, fetchCourseByUrlHandler}}>
        {children}
      </CourseContext.Provider>
    );
  }
  
  export function useCourseContext() {
    return useContext(CourseContext);
  }