// services/api.js
import axios from 'axios';
import API_BASE_URL from './baseurl';

// Function to fetch all branches
export const fetchAllBranches = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/branches`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all branches:", error);
        throw error;
    }
};

// Function to fetch branch details by URL
export const fetchBranchByUrl = async (branchUrl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/branches/${branchUrl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branch by URL:", error);
        throw error;
    }
};


// Function to fetch all Courses
export const fetchAllCourses = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all branches:", error);
        throw error;
    }
};

// Function to fetch all blogs
export const fetchAllBlogs = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch Course details by URL
export const fetchCourseByUrl = async (courseurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/course-details/${courseurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching branch by URL:", error);
        throw error;
    }
};

// Function to fetch all blogs
export const fetchBlogByUrl = async (blogurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blog/${blogurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all blogs
export const fetchAllArticles = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all blogs
export const fetchArticlesByUrl = async (articleurl) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affairs/${articleurl}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all blogs using Date
export const fetchAllBlogsDate = async (s1,e1) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/blogs/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all article using date
export const fetchAllArticlesDate = async (s1,e1) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/upsc-daily-current-affair/${s1}/${e1}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

// Function to fetch all webdetails
export const fetchAllWebdetails = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/webdetails`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}

export const fetchAllNewsheadline = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/newsheadline`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all blogs:", error);
    }
}
