const menu_data = [
    {
        title: 'ABOUT',
        link: '#',
        mega_menu: 'single', // Updated to 'single'
        submenus: [
            { title: 'About Us', link: '/about-us' },
            { title: "WHY SRIRAM's IAS", link: '/why-sriram-ias' },
            { title: "Director's Message", link: '/director-message' }
        ],
        mobile_pages_menu: [
            { title: 'About Us 1', link: '/about-1' },
            { title: 'About Us 2', link: '/about-2' },
            { title: 'About Us 3', link: '/about-3' },
            { title: 'Instructor 1', link: '/team-1' },
            { title: 'Instructor 2', link: '/team-2' },
            { title: 'Instructor 3', link: '/team-3' },
            { title: 'Instructor Profile', link: '/team-details' },
            { title: "Faq's", link: '/faq' },
            { title: "404 Error", link: '/404' },
            { title: "Coming Soon", link: '/coming-soon' },
            { title: 'Gallery Grid', link: '/gallery-grid' },
            { title: 'Gallery Masonry', link: '/gallery-masonry' },
            { title: 'Event Grid', link: '/event-grid' },
            { title: 'Event List', link: '/event-list' },
            { title: 'Event Details', link: '/event-details' },
            { title: 'Pricing Table', link: '/pricing-table' },
            { title: 'Purchase Guide', link: '/purchase-guide' },
            { title: 'Privacy Policy', link: '/privacy-policy' },
            { title: 'Terms & Condition', link: '/terms-condition' },
            { title: 'Sign In', link: '/sign-in' },
            { title: 'Shop', link: '/shop' },
            { title: 'Product Details', link: '/product-details' },
            { title: 'Cart', link: '/cart' },
            { title: 'Wishlist', link: '/wishlist' },
            { title: 'Checkout', link: '/checkout' }
        ]
    },
    {
        title: 'COURSES',
        link: '#',
        mega_menu: 'doubleWithImage', // Updated to 'doubleWithImage'
        submenus: [
            {
            //   title: 'Old Rajinder Nagar',
              links: [
                { title: 'CSAT', link: 'index.html', badge: 'hot' },
                { title: '2 Years General Studies Classroom Foundation Course', link: 'index-distant-learning.html' },
                { title: 'Mains Mastery Course 2025', link: 'index-university.html' },
                { title: '1 Year General Studies Comprehensive Course', link: 'index-online-academy.html', badge: 'hot' },
                // Add more links as needed
              ],
            },
            {
            //   title: 'Mukherjee Nagar',
              links: [
                { title: 'Optional Subject Course', link: 'index-business-coach.html', badge: 'new' },
                { title: 'General Studies Module Course', link: 'index-motivation.html', badge: 'new' },
                { title: 'Programming', link: 'index-programming.html', badge: 'new' },
                // Add more links as needed
              ],
            },
            {
              title: 'Image',
              image: {
                src: '../../../assets/images/others/dropdown.png',
                link: 'https://1.envato.market/5bQ022',
                alt: 'Advertising Image',
              },
            },

          ]
    },
    {
        title: 'KNOWLEGE',
        link: '#',
        mega_menu: 'single', // Updated to 'single'
        submenus: [
            { title: 'Contact Us', link: '/contact-us' },
            { title: 'Contact Me', link: '/contact-me' }
        ]
    },
    {
        title: 'INTERVIEW',
        link: '#',
        mega_menu: 'single', // Updated to 'single'
        submenus: [
            { title: 'Contact Us', link: '/contact-us' },
            { title: 'Contact Me', link: '/contact-me' }
        ]
    },
    {
        title: 'TEST SERIES',
        link: '#',
        mega_menu: 'single', // Updated to 'single'
        submenus: [
            { title: 'Blog Standard', link: '/blog-standard' },
            { title: 'Blog Masonry', link: '/blog-masonry' },
            { title: 'Blog List', link: '/blog-list' },
            { title: 'Blog Details', link: '/blog-details' }
        ]
    },
    {
        title: 'DAILY QUIZ',
        link: '#',
        mega_menu: 'single', // Updated to 'single'
        submenus: [
            { title: 'Blog Standard', link: '/blog-standard' },
            { title: 'Blog Masonry', link: '/blog-masonry' },
            { title: 'Blog List', link: '/blog-list' },
            { title: 'Blog Details', link: '/blog-details' }
        ]
    },
    {
        title: 'MORE',
        link: '#',
        mega_menu: 'single', // Updated to 'single'
        submenus: [
            { title: 'Blog Standard', link: '/blog-standard' },
            { title: 'Blog Masonry', link: '/blog-masonry' },
            { title: 'Blog List', link: '/blog-list' },
            { title: 'Blog Details', link: '/blog-details' }
        ]
    },
    // {
    //     title: 'Gallery',
    //     link: '#',
    //     mega_menu: 'single', // Updated to 'single'
    //     submenus: [
    //         { title: 'Contact Us', link: '/contact-us' },
    //         { title: 'Contact Me', link: '/contact-me' }
    //     ]
    // },
    // {
    //     title: 'Career',
    //     link: '#',
    //     mega_menu: 'single', // Updated to 'single'
    //     submenus: [
    //         { title: 'Contact Us', link: '/contact-us' },
    //         { title: 'Contact Me', link: '/contact-me' }
    //     ]
    // },
    // {
    //     title: 'Books',
    //     link: '#',
    //     mega_menu: 'single', // Updated to 'single'
    //     submenus: [
    //         { title: 'Contact Us', link: '/contact-us' },
    //         { title: 'Contact Me', link: '/contact-me' }
    //     ]
    // },
]

export default menu_data;
