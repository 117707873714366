import React from 'react';

function CustomSingleNavDropDown() {
  return (
    <>
         <ul className="submenu custom-single-drop" style={{width: "50%", background: "none"}}>
            <li className='bg-light w-50'><a ><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp; Videos</a></li>
            <li className='bg-light w-50'><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Gallery</a></li>
            <li className='bg-light w-50'><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Articles</a></li>
            <li className='bg-light w-50'><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Book</a></li>
            <li className='bg-light w-50'><a><span className='course-level bg-success text-white p-2 py-1 rounded-circle'><i className='icon-20 fs-6'></i></span> &nbsp;  Blog</a></li>
         </ul>
    </>
  )
}

export default CustomSingleNavDropDown;