import { createContext, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Instructor from '../ContentManagement/CourseContent/Instructor/Instructor';
import Overview from '../ContentManagement/CourseContent/Overview/Overview';
import Review from '../ContentManagement/CourseContent/Review/Review';

const DetailsButtonContext = createContext();

export function ButtonDetailsProvider({ children }) {
    const [batchselectedid, setBatchselectedid] = useState('1')
    const [buttonDetailsNames, setButtonDetailsNames] = useState([]);
    const [activeDetailsButton, setactiveDetailsButton] = useState('');
    const [hasDetailUpdated, setHasDetailUpdated] = useState(false)

    // Define a mapping of button names to batch IDs
const batchIdMap = {
    'Old Rajinder Nagar': '1',
    'Mukherjee Nagar': '2',
    'Pune': '3',
    'Hyderabad': '4'
};
    // const navigate = useNavigate(); // Initialize useNavigate

     // Function to update button names based on componentName
     const updateButtonDetailsNames = (DetailscomponentName) => {
        const buttonLabels = {
            'Coursedetailpage': [
                { id: 'our-courses-tab', label: 'Our Courses', target: '#ourcourses', component: <Instructor/> },
                { id: 'overview-tab', label: 'Overview', target: '#overview',component: <Overview/> },
                { id: 'curriculum-tab', label: 'Curriculum', target: '#curriculum', component: <Review/> },
                { id: 'instructor-tab', label: 'Instructor', target: '#instructor', component: <Instructor/> },
                { id: 'review-tab', label: 'Reviews', target: '#review' }
            ],
            'BatchCategory': [
                { id: '1', label: 'Old Rajinder Nagar', target: '#1' },
                { id: '2', label: "Mukherjee Nagar", target: '#2' },
                { id: '3', label: "Pune", target: '#3' },
                { id: '4', label: "Hyderabad", target: '#4' }
            ],
            'sriCourse': [
                { id: 'csat-tab', label: 'CSAT', target: '#csat' },
                { id: 'two-years-gs-tab', label: '2 Years G.S Course', target: '#two-years-gs' },
                { id: 'mains-mastery-tab', label: 'Mains Mastery Course 2025', target: '#mains-mastery' },
                { id: 'one-year-gs-tab', label: '1 Year G.S Course', target: '#one-year-gs' },
                { id: 'optional-subject-tab', label: 'Optional Subject Course', target: '#optional-subject' },
                { id: 'gs-module-tab', label: 'G.S Module Course', target: '#gs-module' }
            ],
        };
        
 

        const defaultButtons = buttonLabels['sriAboutUs']; // Fallback
        const buttons = buttonLabels[DetailscomponentName] || defaultButtons;
        setButtonDetailsNames(buttons); 

        // Set default active button based on the component name
        switch (DetailscomponentName) {
            case 'Coursedetailpage':
                setactiveDetailsButton(buttons[0].label); // Default to the second button
                break;
            case 'BatchCategory':
                setactiveDetailsButton(buttons[0].label); // Default to the second button
                break;
            case 'sriCourse':
                setactiveDetailsButton(''); // Default to the second button
                break;
            default:
                setactiveDetailsButton(buttons[0].toLowerCase().replace(/\s+/g, ''));
                break;
        }
    };

    // Function to handle setting the active button
    const handleDetailsButtonClick = (name) => {
        setactiveDetailsButton(name);
        console.log("this is calling function")
        // switch (name) {
        //     case 'Old Rajinder Nagar':
        //         setBatchselectedid('1');
        //         // Handle Teacher Support accordingly
        //         // navigate('/about-us'); // Navigate to /about-us when About Us is clicked
        //         break;
        //     case "Mukherjee Nagar":
        //         setBatchselectedid('2');
        //         // Handle Upload Answers accordingly
        //         // navigate('/why-sriram-ias'); // Navigate to /about-us when About Us is clicked
        //         break;
        //     case "Pune":
        //         setBatchselectedid('3')
        //         // Handle Upload Answers accordingly
        //         // navigate('/director-message'); // Navigate to /about-us when About Us is clicked
        //         break;
        //     case "Hyderabad":
        //         setBatchselectedid('4');
        //         // Handle Upload Answers accordingly
        //         // navigate('/director-message'); // Navigate to /about-us when About Us is clicked
        //         break;
        //     default:
        //         break;
        // }

        // Set batchselectedid based on the name using the batchIdMap
        const batchId = batchIdMap[name];
        if (batchId) {
            setBatchselectedid(batchId);
        }
    };
  
    return (
      <DetailsButtonContext.Provider value={{ buttonDetailsNames, activeDetailsButton, batchselectedid, updateButtonDetailsNames, handleDetailsButtonClick, hasDetailUpdated, setHasDetailUpdated  }}>
        {children}
      </DetailsButtonContext.Provider>
    );
  }
  
  export function useButtonDetailsContext() {
    return useContext(DetailsButtonContext);
  }