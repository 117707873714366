import React from 'react';
import blogimg from "../../../assets/images/blog/blog-25.jpg"

function BlogCard({blogs}) {
  return (
    <>
    {blogs.map((post, index) => (
    <div class="edu-blog blog-style-list" key={index}>
                            <div class="inner">
                                <div class="thumbnail" style={{width: "45%", display: "flex", alignItems: "center"}}>
                                    <a href={`/blog-details/${post.url}`}>
                                        <img src={post?.pic || blogimg} alt="Blog Images responsive-image"/>
                                    </a>
                                </div>
                                <div class="content">
                                    <h5 class="title"><a href={`/blog-details/${post.url}`}>{post.title}</a></h5>
                                    <ul class="blog-meta">
                                        <li><i class="icon-27"></i>{post.blogpostdate}</li>
                                        <li><i class="icon-28"></i>View 09</li>
                                    </ul>
                                    <p>Lorem ipsum dolor sit amet cons tetur adip isicing sed eiusmod.</p>
                                    <div class="read-more-btn">
                                        {/* <a class="edu-btn btn-border btn-medium" href={`/blogs-details/${post.url}`}>Learn More <i class="icon-4"></i></a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
    ))}
    </>
  )
}

export default BlogCard;