import React from 'react';
import { Link } from 'react-router-dom';
import a1 from "../../assets/images/about/about-01.png";
import a2 from "../../assets/images/about/about-02.png";
import a3 from "../../assets/images/about/shape-36.png";
import a4 from "../../assets/images/about/shape-37.png";
import a5 from "../../assets/images/about/shape-02.png";

function About() {
  return (
    <>
    <div className="gap-bottom-equal edu-about-area about-style-1">
            <div className="container edublink-animated-shape">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-6">
                        <div className="about-image-gallery">
                            <img className="main-img-1" src={a1} alt="About Image"/>
                            <div className="video-box" data-sal-delay="150" data-sal="slide-down" data-sal-duration="800">
                                <div className="inner">
                                    <div className="thumb">
                                        <img src={a2} alt="About Image"/>
                                        <a href="https://www.youtube.com/watch?v=PICj5tr9hcc" className="popup-icon video-popup-activation">
                                            <i className="icon-18"></i>
                                        </a>
                                    </div>
                                    <div className="loading-bar">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="award-status bounce-slide">
                                <div className="inner">
                                    <div className="icon">
                                        <i className="icon-21"></i>
                                    </div>
                                    <div className="content">
                                        <h6 className="title">30+</h6>
                                        <span className="subtitle">Years Experienced</span>
                                    </div>
                                </div>
                            </div>
                            <ul className="shape-group">
                                <li className="shape-1 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img data-depth="1" src={a3} alt="Shape"/>
                                </li>
                                <li className="shape-2 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img data-depth="-1" src={a4} alt="Shape"/>
                                </li>
                                <li className="shape-3 scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                                    <img data-depth="1" src={a5} alt="Shape"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6" data-sal-delay="150" data-sal="slide-left" data-sal-duration="800">
                        <div className="about-content">
                            <div className="section-title section-left">
                                <span className="pre-title">About Us</span>
                                <h2 className="title">Transforming Aspirations <span className="color-secondary">into Achievements</span></h2>
                                <span className="shape-line"><i className="icon-19"></i></span>
                                <p>Founded nearly four decades ago by SRIRAM Sir, SRIRAM’s IAS has established itself as one of India’s premier institutes for Civil Services preparation. With a steadfast vision to become a Centre of Excellence, we are dedicated to our mission of developing knowledgeable and innovative leaders.</p>
                                    <p>For over 39 years, we've been mentoring candidates preparing for the Civil Services Examination, guiding them on their journey to prestigious government roles. Our experienced team understands the dynamic nature of UPSC Civil Services examination as we continuously innovate to adapt to the changing demands of the exam.  </p>
                            </div>
                            <div className="course-view-all" data-sal-delay="150" data-sal="slide-up" data-sal-duration="1200">
                                    <Link to='/about-us'>
                                    <a className="edu-btn text-white">View More <i className="icon-4"></i></a>
                                    </Link>
                                </div>
                            {/* <ul className="features-list">
                                <li>Expert Trainers</li>
                                <li>Online Remote Learning</li>
                                <li>Lifetime Access</li>
                            </ul> */}
                        </div>
                    </div>
                </div>
                {/* <ul className="shape-group">
                    <li className="shape-1 circle scene" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
                        <span data-depth="-2.3"></span>
                    </li>
                </ul> */}
            </div>
        </div>
    </>
  )
}

export default About;