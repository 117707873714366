import { createContext, useState, useContext } from 'react';
import { fetchAllBlogs, fetchBlogByUrl } from '../../Config/api';

const BlogsContext = createContext();

export function BlogsProvider({ children }) {
    const [blogs, setBlogs] = useState([]);
    const [blogDetails, setBlogDetails] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
   
      // Fetch all blogs data
      const fetchAllBlogsHandler = async () => {
        setLoading(true);
        try {
            const data = await fetchAllBlogs();
            setBlogs(data.data); // Set branches data from response
            setError(null);
        } catch (err) {
            console.error("Error fetching branches:", err);
            setError("Failed to load branches");
        } finally {
            setLoading(false);
        }
    };

        // Fetch single blog data based on URL 
        const fetchBlogByUrlHandler = async (blogUrl) => {
          setLoading(true);
          try {
              const data = await fetchBlogByUrl(blogUrl);
              setBlogDetails(data.data[0])
              console.log(blogDetails)
              // return data.data; // Return branch data from response
          } catch (err) {
              console.error("Error fetching branch by URL:", err);
              setError("Failed to load branch by URL");
              return null;
          } finally {
              setLoading(false);
          }
      }; 
  
    return (
      <BlogsContext.Provider value={{ blogs, blogDetails, loading, error, fetchAllBlogsHandler, fetchBlogByUrlHandler}}>
        {children}
      </BlogsContext.Provider>
    );
  }
  
  export function useBlogsContext() {
    return useContext(BlogsContext);
  }