import React from 'react';
import NavbarwithImage from '../NavbarwithImage/NavbarwithImage';
import DoubleNavbar from '../DoubleNavbar/DoubleNavbar';
import SingleNabar from '../SingleNabar/SingleNabar1';
import menu_data from '../../../Data/menu-data';
import { Link } from 'react-router-dom';
import CustomSingleNavDropDown from '../CustomNavDropDown/CustomSingleNavDropDown';
import CustomSingleNavDropDown1 from '../CustomNavDropDown/CustomSingleNavDropDown1';
import CustomNavDropDownWithImage from '../CustomNavDropDownWithImage/CustomNavDropDownWithImage';
import SingleNabar2 from '../SingleNabar/SingleNabar2';

// Mapping each menu title to its corresponding mega_menu type
const megaMenuMapping = {
    // COURSES: 'DoublewithImage',
    COURSES: 'single',
    Pages: 'Double',
    ABOUT: 'single',
    KNOWLEGE: 'single',
    MORE: 'single',
    // Add other mappings as needed
};

function MainMenu() {
  return (
    <ul className="mainmenu">
      {menu_data.map((menu, i) => {
          // Determine mega_menu type based on the menu title
          const megaMenuType = megaMenuMapping[menu.title] || null;

          return (
              <li
                  key={i}
                  className={(menu.title === 'Home' || menu.title === 'Videos' || menu.title === 'TEST SERIES' || menu.title === 'DAILY QUIZ'  || menu.title === 'Gallery' || menu.title === 'INTERVIEW' || menu.title === 'Career' || menu.title === 'Books') ? '' : 'has-droupdown'}
              >
                  <a href={
                      menu.title === 'Home' ? '/' :
                      menu.title === 'Videos' ? '/videos' :
                      menu.title === 'Gallery' ? '/gallery' :
                      menu.title === 'Interview' ? '/interview' :
                      menu.title === 'Career' ? '/career' :
                      menu.title === 'Books' ? '/books' : '#'
                  }>
                      {menu.title}
                  </a>

                  {/* Render the appropriate component based on the mega_menu type */}
                  {megaMenuType === 'single' && menu.title === 'ABOUT' && <SingleNabar />}
                  {megaMenuType === 'single' && menu.title === 'KNOWLEGE' && <CustomSingleNavDropDown />}
                  {megaMenuType === 'single' && menu.title === 'MORE' && <CustomSingleNavDropDown1 />}
                  {megaMenuType === 'single' && menu.title === 'COURSES' && <SingleNabar2 />}
                  {megaMenuType === 'Double' && <DoubleNavbar />}
                  {/* {megaMenuType === 'DoublewithImage' && <NavbarwithImage menuData={menu.submenus} />} */}
                  {/* {megaMenuType === 'DoublewithImage' && <CustomNavDropDownWithImage/>} */}

                  {/* Render the submenu for non-mega menu items */}
                  {/* {!menu.mega_menu && !megaMenuType && menu.title !== 'Home' && menu.title !== 'Videos' && menu.title !== 'Gallery' && menu.title !== 'Knowledge' && menu.title !== 'Interview' && menu.title !== 'Career' && menu.title !== 'Books' && (
                      <ul className="submenu">
                          {menu.submenus.map((nav, j) => (
                              <li key={j}>
                                  <Link to={`${nav.link}`}>
                                      {nav.title}
                                      {nav?.hot && <span className="badge-1">hot</span>}
                                      {nav?.new && <span className="badge">new</span>}
                                  </Link>
                              </li>
                          ))}
                      </ul>
                  )} */}

                  {/* Render the mega menu items */}
                  {/* {menu.mega_menu && (
                      <ul className="mega-menu">
                          {menu.submenus.map((nav, j) => (
                              <li key={j}>
                                  <h6 className="menu-title">{nav.title}</h6>
                                  <ul className="submenu mega-sub-menu-01">
                                      {nav.mega_submenu && nav.mega_submenu.map((m, k) => (
                                          <li key={k}>
                                              <Link to={`${m.link}`}>
                                                  {m.title}
                                              </Link>
                                          </li>
                                      ))}
                                  </ul>
                              </li>
                          ))}
                      </ul>
                  )} */}
              </li>
          );
      })}
    </ul>
  );
}

export default MainMenu;
