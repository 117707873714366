import React from 'react';
import img from "../../assets/images/banner/BG-1.webp";

function Coursescard({batchs, branchname}) {


  return (
    <>
                    <div className="row g-5">
                    {/* <!-- Start Single Course  --> */}
                    {batchs.map((batch, index) => (
                    <div className="col-md-6 col-xl-3" key={index}>
                        <div className="edu-course course-style-1 hover-button-bg-white">
                            <div className="inner">
                                <div className="thumbnail">
                                    <a href="course-details.html">
                                        <img src={batch.pic?batch.pic : img } className='responsive-image' alt="Course Meta"/>
                                    </a>
                                    <div className="time-top">
                                        <span className="duration"><i className="icon-61"></i>4 Weeks</span>
                                    </div>
                                </div>
                                <div className="content custom-content1">
                                    <span className="course-level">{branchname[(batch.branchid-1)]}</span>
                                    <h6 className="title">
                                        <a href="#">{batch.batchname}</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /8 Rating)</span>
                                    </div>
                                    <div className="course-price">$49.00</div>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>13 Lessons</li>
                                        <li><i className="icon-25"></i>28 Students</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="course-hover-content-wrapper">
                                <button className="wishlist-btn"><i className="icon-22"></i></button>
                            </div>
                            <div className="course-hover-content">
                                <div className="content">
                                    {/* <button className="wishlist-btn"><i className="icon-22"></i></button> */}
                                    <span className="course-level">{branchname[(batch.branchid-1)]}</span>
                                    <h6 className="title">
                                        <a href="course-details.html">{batch.batchname}</a>
                                    </h6>
                                    <div className="course-rating">
                                        <div className="rating">
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                            <i className="icon-23"></i>
                                        </div>
                                        <span className="rating-count">(4.9 /8 Rating)</span>
                                    </div>
                                    <div className="course-price">$49.00</div>
                                    <p>Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod tempor.</p>
                                    <ul className="course-meta">
                                        <li><i className="icon-24"></i>13 Lessons</li>
                                        <li><i className="icon-25"></i>28 Students</li>
                                    </ul>
                                    <a href="course-details.html" className="edu-btn btn-secondary btn-small">Enrolled <i className="icon-4"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                     ))}
                                         {/* <!-- Start Single Course  --> */}
                </div>
    
    </>
  )
}

export default Coursescard;