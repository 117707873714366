import React from 'react';
import Batchcard from '../Batchcard/Batchcard';
import  blog_data from '../../Data/blog-data';

function Batch() {
    const blogItems = blog_data.filter(b => b.home_1); 
  return (
    <>
    <div className="edu-blog-area blog-area-1 edu-section-gap bg-lightenharsh">
            <div className="container">
                <div className="section-title section-center" data-sal-delay="100" data-sal="slide-up" data-sal-duration="800">
                    <span className="pre-title">OUR BRANCHES</span>
                    <h2 className="title">Study with SRIRAM's IAS</h2>
                    <span className="shape-line"><i className="icon-19"></i></span>
                </div>
                <div className="row g-5">
                    {/* <!-- Start Blog Grid  --> */}
                    {blogItems.map((blogItem, i) => ( 
                        <div key={i} className="col-lg-3 col-md-6 col-12" data-sal-delay={blogItem.delay} data-sal="slide-up" data-sal-duration="800">
                            <Batchcard param={blogItem}/>
                        </div> 
                    ))} 
                    {/* <!-- End Blog Grid  --> */}
                    </div>
            </div>
            <ul className="shape-group">
                <li className="shape-1 scene">
                    <img data-depth="-1.4" src="assets/images/about/shape-02.png" alt="Shape"/>
                </li>
                <li className="shape-2 scene">
                    <span data-depth="2.5"></span>
                </li>
                <li className="shape-3 scene">
                    <img data-depth="-2.3" src="assets/images/counterup/shape-05.png" alt="Shape"/>
                </li>
            </ul>
        </div>
    
    
    </>
  )
}

export default Batch