import React from 'react'

function PopularCard({articles}) {
  return (
    <>
        {articles.map((post, index) => (
       <div className="latest-post" key={index}>
                                            <div className="post-content">
                                                <h6 style={{color:"#301C6F", textTransform:"lowercase"}} className="title d-flex"><i class="icon-20"></i>&nbsp;<a href={`/article-details/${post.url}`}>{post.title}</a></h6>
                                                <ul className="blog-meta">
                                                    <li><i className="icon-27"></i>{post.articlepostdate}</li>
                                                </ul>
                                            </div>
                                        </div>
        ))}
    </>
  )
}

export default PopularCard;
