import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useBranchContext } from '../../Context/Branchapi';

function SingleNabar() {
  const { branches, loading, error, fetchAllBranchesHandler } = useBranchContext();

  useEffect(() => {
    fetchAllBranchesHandler(); // Fetch branches on component mount
  }, []);


  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  return (
    <>
     <ul className="submenu custom-single-drop">
     {branches.map((branch) => (
                <li key={branch.branchurl}>
                    <a href={`/branch/${branch.branchurl}`}><span className='course-level bg-success text-white p-2 py-1 rounded-circle' style={{width: "50px", height: "50px"}}><i className='icon-20 fs-6'></i></span> &nbsp; {branch.branchname}</a>
                </li>
            ))}
                                            </ul>
    </>
  )
}

export default SingleNabar;