import React, { useState } from 'react';
import { useDateContext } from '../../Context/Dateapi';

const Calendar = () => {
  // Get current date, month, and year
  const today = new Date();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [showMonthPicker, setShowMonthPicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Store selected date
  // const [selectedMonthindex, setSelectedMonthindex] = useState() 

  const { startdate, enddate, loading, error, handleStartDateChange, handleEndDateChange, handleSubmitBlog, handleSubmitarticle } =
    useDateContext();

  // Month names
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

    // Toggle month picker
  const toggleMonthPicker = () => {
    setShowMonthPicker(!showMonthPicker);
  };

  // Handle month selection from the grid
  const selectMonth = (monthIndex) => {
    const newDate = new Date(currentDate.getFullYear(), monthIndex, 1);
    setCurrentDate(newDate);
    setShowMonthPicker(false);
  };

  // Function to get number of days in a month
  const daysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  // Function to render the calendar days
  const renderCalendarDays = () => {
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    const daysInCurrentMonth = daysInMonth(currentMonth, currentYear);

    // Create an array to hold the calendar cells
    const calendarCells = [];

    // Fill the blank days before the first day of the month
    for (let i = 0; i < firstDay; i++) {
      calendarCells.push(<div key={`empty-${i}`} className="empty-cell"></div>);
    }

    // Fill the actual days of the month
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      const isToday =
        day === today.getDate() &&
        currentMonth === today.getMonth() &&
        currentYear === today.getFullYear();

        const isSelected = day === selectedDate;

      calendarCells.push(
        <div
          key={day}
          className={`calendar-day ${isToday ? 'today' : ''} ${isSelected ? 'selected' : ''}`}
          onClick={(e) => handleDateSelection(e,day)}
        >
          {day}
        </div>
      );
    }

    return calendarCells;
  };


  const handleDateSelection = (e,day) => {
    e.preventDefault(); // Prevent default behavior if necessary

    // Format the selected date as "8/11/2024"
    // const formattedDate = `${currentYear}-${currentMonth + 1}-0${day}`;
 // Create a new date using local time without converting to UTC
 const selectedDate = new Date(currentYear, currentMonth, day);

 // Format the date as "YYYY-MM-DD" manually, considering local timezone
 const year = selectedDate.getFullYear();
 const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
 const date = String(selectedDate.getDate()).padStart(2, '0'); // Add leading zero if needed

 const formattedDate = `${year}-${month}-${date}`;


    // Store the selected date in the state
    setSelectedDate(day); 
    // Here, you can call a function to handle the selected date, for example:
    handleStartDateChange({ target: { value: formattedDate } });  // If you want to use the context or handle it elsewhere
      handleEndDateChange({ target: { value: formattedDate } });  // If you want to use the context or handle it elsewhere
      // setTimeout(()=>{
        handleSubmitarticle(e,startdate, enddate);
      // },500)
};

 // Use effect to handle state changes and ensure immediate updates
//  useEffect(() => {
  // if (e) {
    // Trigger the onChange function passed from the parent
    // handleSubmitarticle(e.preventDefault,startdate, enddate);
  // }
// }, [startdate,enddate, handleSubmitarticle]);

  // Navigation functions
  const goToPreviousMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const goToNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  return (
    <div className="calendar">
      <div className="calendar-header highlight-custom-background">
        <button onClick={goToPreviousMonth}><i className='icon-angle-left fw-bold'></i></button>
        <h2 onClick={toggleMonthPicker}>  {!showMonthPicker ? `${months[currentMonth]} ${currentYear}` : months[currentMonth]}</h2>
        <button onClick={goToNextMonth}><i className='icon-angle-right fw-bold'></i></button>
      </div>
      <div className="calendar-body">
      {showMonthPicker ? (
        // Month Picker Grid
        <div className="month-picker">
          {months.map((month, index) => (
            <div
              key={index}
              className={`month ${index === currentDate.getMonth() ? 'selected-month' : ''}`}
              onClick={() => selectMonth(index)}
            >
              {month}
            </div>
          ))}
        </div>
      ):(
        <>
        <div className="calendar-weekdays">
          <div>Sun</div>
          <div>Mon</div>
          <div>Tue</div>
          <div>Wed</div>
          <div>Thu</div>
          <div>Fri</div>
          <div>Sat</div>
        </div>
        <div className="calendar-days">
          {renderCalendarDays()}
        </div>
        </>
        )}
      </div>
    </div>
  );
};

export default Calendar;

// import React, { useState } from 'react';
// import './Calendar.scss';

// const Calendar = () => {
//   const [currentDate, setCurrentDate] = useState(new Date());
//   const [showMonthPicker, setShowMonthPicker] = useState(false);

//   const months = [
//     'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December'
//   ];

//   // Get days in the current month
//   const getDaysInMonth = (year, month) => {
//     return new Date(year, month + 1, 0).getDate();
//   };

//   // Move to the previous year
//   const prevYear = () => {
//     const prevDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 1));
//     setCurrentDate(new Date(prevDate));
//   };

//   // Move to the next year
//   const nextYear = () => {
//     const nextDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
//     setCurrentDate(new Date(nextDate));
//   };

//   // Toggle month picker
//   const toggleMonthPicker = () => {
//     setShowMonthPicker(!showMonthPicker);
//   };

//   // Handle month selection from the grid
//   const selectMonth = (monthIndex) => {
//     const newDate = new Date(currentDate.getFullYear(), monthIndex, 1);
//     setCurrentDate(newDate);
//     setShowMonthPicker(false);
//   };

//   // Generate the days of the month
//   const generateDays = () => {
//     const year = currentDate.getFullYear();
//     const month = currentDate.getMonth();
//     const daysInMonth = getDaysInMonth(year, month);
//     const firstDayOfMonth = new Date(year, month, 1).getDay();
//     const days = [];

//     // Empty cells before the first day
//     for (let i = 0; i < firstDayOfMonth; i++) {
//       days.push(<div key={`empty-${i}`} className="calendar-day empty"></div>);
//     }

//     // Actual days of the month
//     for (let day = 1; day <= daysInMonth; day++) {
//       const isToday =
//         day === new Date().getDate() &&
//         month === new Date().getMonth() &&
//         year === new Date().getFullYear();

//       days.push(
//         <div key={day} className={`calendar-day ${isToday ? 'today' : ''}`}>
//           {day}
//         </div>
//       );
//     }

//     return days;
//   };

//   return (
//     <div className="calendar">
//       <div className="calendar-header">
//         <button onClick={prevYear}>&lt;&lt;</button>
//         <h2 onClick={toggleMonthPicker}>
//           {currentDate.getFullYear()}
//         </h2>
//         <button onClick={nextYear}>&gt;&gt;</button>
//       </div>

//       {showMonthPicker ? (
//         // Month Picker Grid
//         <div className="month-picker">
//           {months.map((month, index) => (
//             <div
//               key={index}
//               className={`month ${index === currentDate.getMonth() ? 'selected-month' : ''}`}
//               onClick={() => selectMonth(index)}
//             >
//               {month}
//             </div>
//           ))}
//         </div>
//       ) : (
//         // Calendar View
//         <div className="calendar-body">
//           <div className="calendar-weekdays">
//             <div>Sun</div>
//             <div>Mon</div>
//             <div>Tue</div>
//             <div>Wed</div>
//             <div>Thu</div>
//             <div>Fri</div>
//             <div>Sat</div>
//           </div>
//           <div className="calendar-days">{generateDays()}</div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Calendar;
