import React from 'react';
import img9 from "../../../assets/images/blog/small-post-01.jpg";

function PostCard({blogs}) {
  return (
    <>
    {blogs.map((post, index) => (
    <div className="latest-post">
                                            <div className="thumbnail" style={{width: "40%"}}>
                                                <a href={`/blog-details/${post.url}`}>
                                                    <img src={post?.pic || img9} alt="Blog Images responsive-image1" />
                                                </a>
                                            </div>
                                            <div className="post-content">
                                                <h6 className="title"><a href={`/blog-details/${post.url}`}>{post.title}</a></h6>
                                                <ul className="blog-meta">
                                                    <li><i className="icon-27"></i>{post.blogpostdate}</li>
                                                </ul>
                                            </div>
                                        </div>
    ))}
    </>
  )
}

export default PostCard;